<template>
    <ContentLoader v-slot :loading="item == null" class="h-screen-header">
        <Hero :image="item.image">
            <div class="flex flex-col justify-between h-56">
                <div class="ml-4 flex flex-col justify-start items-start z-10">
                    <div class="flex items-center ml-1">
                        <div class="text-4xl font-medium text-clip overflow-hidden h-10">{{ item.name }}</div>
                        <button class="btn btn-active btn-sm ml-2" @click.stop="addFavouriteArtist">
                            <svg xmlns="http://www.w3.org/2000/svg" :class="['h-6', 'w-6', isFavourite ? 'fill-current' : '']" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                        </button>
                    </div>
                    <div class="flex flex-col my-2 text-gray-600">
                        <span>{{ item.albumCount }}张专辑 · {{ item.trackCount }} 首歌曲</span>
                    </div>
                    <div v-if="item.description" class="mb-3 text-gray-600 text-ellipsis overflow-hidden h-24 cursor-pointer"
                         onclick="my_modal_2.showModal()">
                        {{ item.description }}
                    </div>
                    <div v-if="item.genres.length > 0">
                        <div class="badge badge-neutral" v-for="(genre, index) in item.genres" :key="genre">
                            <span v-if="index > 0">•</span>
                            <router-link :to="{ name: 'genre', params: { id: genre } }">
                                {{ genre }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="ml-4 z-10 flex">
                    <button class="btn btn-neutral btn-active btn-sm" @click="playNow">
                        Play
                    </button>
                    <div class="drawer drawer-end w-16 ml-2">
                        <input id="my-drawer-4" type="checkbox" class="drawer-toggle" />
                        <div class="drawer-content">
                            <!-- Page content here -->
                            <label for="my-drawer-4" class="btn btn-neutral btn-active btn-sm" @click="handleEditArtist">EDIT</label>
                        </div>
                        <div class="drawer-side">
                            <label for="my-drawer-4" aria-label="close sidebar" class="drawer-overlay"></label>
                            <div class="p-4 w-80 min-h-full bg-base-200 text-base-content">
                                <div class="font-medium text-xl mb-4">编辑艺术家信息</div>

                                <div class="mb-1">艺术家:</div>
                                <div>
                                    <input type="text"
                                        v-model="artistFormData.name"
                                        class="input input-bordered input-sm w-full max-w-xs" />
                                </div>
                                <div class="mb-1 mt-1">艺术家封面:</div>
                                <div>
                                    <bk-upload
                                        :files="files1"
                                        :theme="'picture'"
                                        :multiple="false"
                                        :with-credentials="true"
                                        :header="uploadHeader"
                                        :handle-res-code="handleRes"
                                        :size="{ maxFileSize: 5, maxImgSize: 20 }"
                                        :url="uploadUrl"
                                        name="upload_file"
                                    ></bk-upload>
                                </div>
                                <div class="mb-1 mt-1">描述:</div>
                                <textarea class="textarea textarea-bordered textarea-xs w-full max-w-xs h-48" placeholder="." v-model="artistFormData.description"></textarea>
                                <button class="btn btn-neutral btn-sm btn-wide" @click="handleSave">保存</button>
                                <div class="mt-4">封面推荐:</div>
                                <div v-for="(item2, index) in artistsData" :key="index">
                                    <div class="flex justify-center items-center mt-1 mb-2">
                                        <bk-image fit="contain" :src="item2.artist_img"
                                            class="mask mask-squircle w-16 h-16 cursor-pointer"
                                            @click="handleCopy('artist_img', item2.artist_img)">
                                        </bk-image>
                                        <div class="flex-1 ml-2">
                                            <div class="cursor-pointer hover:underline"
                                                @click="handleCopy('name', item2.artist)">{{item2.artist}}</div>
                                            <div class="truncate w-56 text-gray-400 cursor-pointer hover:underline"
                                                @click="handleCopy('description', item2.desc)">{{item2.desc}}</div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-neutral btn-active btn-sm" onclick="artist_delete.showModal()">
                        DELETE
                    </button>
                </div>
            </div>
        </Hero>
        <div class="overflow-y-auto" style="height: calc(100vh - 380px);">
            <template v-if="item.topTracks.length > 0">
                <div class="flex justify-between mt-5 mb-2 cursor-pointer">
                    <h3 class="my-0 ml-2">
                        热门歌曲
                    </h3>
                    <div class="mr-2 cursor-pointer link" @click="viewAll(item)">
                        查看全部
                    </div>
                </div>
                <TrackList :tracks="item.topTracks.slice(0, 5)" no-artist />
            </template>

            <template v-if="item.albums.length > 0">
                <h3 class="mt-5 mb-1">
                    最新专辑
                </h3>
                <AlbumList :items="item.albums.slice(0, 12)" small />
            </template>

            <template v-if="item.similarArtist.length > 0">
                <h3 class="mt-5 mb-1">
                    相似艺术家
                </h3>
                <ArtistList :items="item.similarArtist" />
            </template>
        </div>
        <!-- Open the modal using ID.showModal() method -->
        <dialog id="my_modal_2" class="modal">
            <div class="modal-box">
                <h3 class="text-lg font-bold">艺术家简介:{{item.name}}</h3>
                <div class="py-4">
                    <textarea class="textarea textarea-bordered textarea-xs w-full h-96" placeholder="." v-model="item.description"></textarea>
                </div>
            </div>
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
        <dialog id="artist_delete" class="modal modal-bottom sm:modal-middle">
            <div class="modal-box">
                <h3 class="text-lg font-bold">删除艺术家</h3>
                <p class="py-4">从音乐收藏中删除此艺术家，但不会删除源文件。</p>
                <div class="modal-action">
                    <form method="dialog">
                        <!-- if there is a button in form, it will close the modal -->
                        <button class="btn btn-neutral btn-sm mr-2" @click="handleDelete">确认</button>
                        <button class="btn btn-sm">取消</button>
                    </form>
                </div>
            </div>
        </dialog>
    </ContentLoader>
</template>
<script>
    import Hero from '../../../components/library/Hero'
    import AlbumList from '@/views/library/album/AlbumList.vue'
    import ArtistList from '@/views/library/artist/ArtistList.vue'
    import TrackList from '@/views/library/track/TrackList.vue'
    import ContentLoader from '@/components/library/ContentLoader'

    export default {
        components: {
            Hero,
            ContentLoader,
            AlbumList,
            ArtistList,
            TrackList
        },
        data() {
            return {
                item: null,
                id: this.$route.params.id,
                customSettings: {
                    isShow: false,
                    title: '艺术家详情'
                },
                artistsData: [],
                isFavourite: false,
                uploadHeader: [
                    {name: 'X-CSRFToken', value: this.getCookie('django_vue_cli_csrftoken')},
                    {name: 'AUTHORIZATION', value: this.getCookie('AUTHORIZATION')}
                ],
                files1: [],
                uploadUrl: '/apimt/upload_image/',
                artistFormData: {},
                reloadImg: false
            }
        },
        watch: {
            id: {
                immediate: true,
                async handler(value) {
                    this.item = null
                    this.item = await this.$api.subsonic.getArtistDetails(value)
                    this.isFavourite = this.item.favourite
                }
            }
        },
        methods: {
            playNow() {
                return this.$store.dispatch('playTrackList', {
                    tracks: this.item.topTracks
                })
            },
            shuffleNow() {
                return this.$store.dispatch('player/shuffleNow', {
                    tracks: this.item.topTracks
                })
            },
            toggleFavourite() {
                return this.favouriteStore.toggle('artist', this.id)
            },
            handleEditArtist() {
                if (this.item.image) {
                    this.files1 = [
                        {
                            name: 'cover.png',
                            status: 'done',
                            url: this.item.image
                        }
                    ]
                }
                this.artistFormData = {
                    name: this.item.name,
                    description: this.item.description
                }
                this.$api.Task.fetchArtists({
                    title: this.item.name,
                    resource: 'kuwo',
                    full_path: ''
                }).then((res) => {
                    this.artistsData = res.data
                })
            },
            handleSave() {
                this.$api.Task.postArtist({
                    id: this.id,
                    artist_img: this.artistFormData.artist_img,
                    description: this.artistFormData.description,
                    name: this.artistFormData.name
                }).then((res) => {
                    if (res.result) {
                        this.customSettings.isShow = false
                        this.$myMsg.notify({
                            title: '修改成功！',
                            type: 'success',
                            time: 2000,
                        })
                        this.$api.subsonic.getArtistDetails(this.id).then((res) => {
                            this.item = res
                        })
                    } else {
                        this.$myMsg.notify({
                            title: '修改失败！',
                            type: 'error',
                            time: 2000,
                        })
                    }
                })
            },
            handleDelete() {
                this.$api.Task.deleteArtist({ 'id': this.id }).then((res) => {
                    if (res.result) {
                        this.$myMsg.notify({
                            title: '删除成功！',
                            type: 'success',
                            time: 2000,
                        })
                        this.$router.push({path: '/artists/most-albums'})
                    } else {
                       this.$myMsg.notify({
                            title: '删除失败！',
                            type: 'error',
                            time: 2000,
                        })
                    }
                })
            },
            addFavouriteArtist() {
                if (this.isFavourite) {
                    this.$api.subsonic.removeFavourite(this.item.id, 'artist').then((res) => {
                        this.isFavourite = false
                    })
                } else {
                    this.$api.subsonic.addFavourite(this.item.id, 'artist').then((res) => {
                        this.isFavourite = true
                    })
                }
            },
            // 渲染图片
            handleRes(response) {
                if (response.result) {
                    this.artistFormData.artist_img = response.data
                    return true
                } else {
                    return false
                }
            },
            handleCopy(k, v) {
                if (k === 'artist_img') {
                    this.files1 = [
                        {
                            name: 'cover.png',
                            status: 'done',
                            url: v
                        }
                    ]
                    this.artistFormData.artist_img = v
                } else {
                    this.artistFormData[k] = v
                }
            },
            viewAll(item) {
                this.$router.push({path: `/artist/tracks/${item.id}`, query: {name: item.name}})
            }
        }
    }
</script>
