<template>
    <div ref="recordview" :class="currentTheme === 'dark' ? 'body-dark' : currentTheme === 'light' ? 'body' : ''">
        <div class="flex flex-col items-center">
            <div class="flex justify-center my-2">
                <div role="tablist" class="tabs tabs-bordered">
                    <a role="tab" :class="selectPeriod === 'week' ? 'tab tab-active' : 'tab'" @click="handleSelectPeriod('week')">Last.week</a>
                    <a role="tab" :class="selectPeriod === 'month' ? 'tab tab-active' : 'tab'" @click="handleSelectPeriod('month')">Last.month</a>
                    <a role="tab" :class="selectPeriod === 'year' ? 'tab tab-active' : 'tab'" @click="handleSelectPeriod('year')">Last.year</a>
                </div>
            </div>
            <div class="h-80 shadow-xl rounded-xl bg-base-100 mx-2 p-8 md:w-[75rem]">
                <div class="font-medium">
                    {{ week_record.date_range }}
                </div>
                <div class="divider divider-neutral !my-0"></div>
                <div class="flex flex-wrap">
                    <div class="w-1/2 mt-14 ml-4">
                        <div>音乐播放</div>
                        <div class="text-6xl font-medium">{{ week_record.this_week_total }}</div>
                        <div class="badge badge-outline tooltip tooltip-right cursor-pointer"
                            :data-tip="week_record.last_week_total"> {{ week_record.week_rate }}% vs. {{ selectPeriod === 'week' ? '上周' : selectPeriod === 'month' ? '上月' : '去年' }}
                        </div>
                        <div class="text-sm font-light mt-1">{{ week_record.jocker }}</div>
                    </div>
                    <div id="weekly" class="md:h-72 w-32 h-56 flex-1"></div>
                </div>
            </div>
            <div class="font-medium text-xl mt-8 underline-offset-4 underline">最佳音乐</div>
            <div class="flex flex-col md:flex-row mt-1 justify-center">
                <div class="shadow-xl rounded-xl md:w-96 w-80 md:mr-4">
                    <div class="bg-base-100 h-24 shadow-xl rounded-xl p-4 bg-neutral text-white" id="example-neutral">
                        <div class="flex items-center">
                            <div>艺术家</div>
                            <div class="badge badge-outline ml-2 text-xs">{{prefectMusic.artist.rate}}</div>
                        </div>
                        <div class="mt-1 flex justify-between">
                            <div class="text-4xl">{{prefectMusic.artist.count}}</div>
                            <div class="flex">
                                <svg t="1727701285115" class="fill-white w-8 h-8" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="1502" width="200" height="200">
                                    <path
                                        d="M160 608c-19.2 0-32 12.8-32 32v128c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-128c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v128c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-128c0-54.4 41.6-96 96-96z m320-320c-19.2 0-32 12.8-32 32v512c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-512c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v512c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-512c0-54.4 41.6-96 96-96z m320 320c-19.2 0-32 12.8-32 32v256c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-256c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v256c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-256c0-54.4 41.6-96 96-96z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div v-for="(artist, index) in prefectMusic.artist_record" :key="'art'+index">
                            <div v-if="index===0" class="tile-item h-full relative">
                                <img v-if="artist[2]" :src="callCoverArtUrl(artist[2])"
                                    class="rounded-t-xl contrast-75 !h-72">
                                <img v-else src="/static/dist/img/album_null.jpg"
                                    class="rounded-xl contrast-75 !h-72">
                                <div class="px-4 absolute bottom-1 w-full">
                                    <div class="px-2 bg-neutral w-max text-sm text-white rounded">最佳艺术家</div>
                                    <div class="flex justify-between text-white items-end">
                                        <div class="text-xl font-light">#{{ index + 1 }} {{ artist[0] }}</div>
                                        <div class="text-sm font-light">{{ artist[1] }}次记录</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else
                                :class="['flex justify-between px-4', index ===1 ? 'mt-2' : '', index ===4 ? 'mb-2' : '']">
                                <div class="text-sm mb-0.5">#{{ index + 1 }} {{ artist[0] }}</div>
                                <div class="text-sm">{{ artist[1] }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 bg-base-100 rounded-xl p-4">
                        <div class="flex justify-between">
                            <div class="flex-1">
                                <div class="bg-neutral w-max px-2 text-white rounded">最佳新艺术家</div>
                                <div class="font-light text-sm px-1">{{prefectMusic.artist.newest[1]}}次记录</div>
                                <div class="text-sm px-1">{{prefectMusic.artist.newest[0]}}</div>
                            </div>
                            <div class="w-16">
                                <div class="tile-item h-full">
                                    <img v-if="prefectMusic.artist.newest[2]" :src="callCoverArtUrl(prefectMusic.artist.newest[2])"
                                        class="rounded-xl contrast-75 !w-16">
                                    <img v-else src="/static/dist/img/album_null.jpg"
                                        class="rounded-xl contrast-75">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shadow-xl rounded-xl md:w-96 md:mr-4 md:mt-0 mt-4">
                    <div class="bg-base-100 h-24 shadow-xl rounded-xl p-4 bg-neutral-content">
                        <div class="flex items-center">
                            <div>专辑</div>
                            <div class="badge badge-outline ml-2 text-xs">{{prefectMusic.artist.rate}}</div>
                        </div>
                        <div class="mt-1 flex justify-between">
                            <div class="text-4xl">{{prefectMusic.album.count}}</div>
                            <div class="flex">
                                <svg t="1727701285115" class="fill-current w-8 h-8" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="1502" width="200" height="200">
                                    <path
                                        d="M160 608c-19.2 0-32 12.8-32 32v128c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-128c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v128c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-128c0-54.4 41.6-96 96-96z m320-320c-19.2 0-32 12.8-32 32v512c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-512c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v512c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-512c0-54.4 41.6-96 96-96z m320 320c-19.2 0-32 12.8-32 32v256c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-256c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v256c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-256c0-54.4 41.6-96 96-96z"
                                        fill="#374E6B" p-id="1503"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div v-for="(artist, index) in prefectMusic.album_record" :key="'alb'+index">
                            <div v-if="index===0" class="tile-item h-full relative">
                                <img v-if="artist[2]" :src="callCoverArtUrl(artist[2])"
                                    class="rounded-t-xl contrast-75 !h-72">
                                <img v-else src="/static/dist/img/album_null.jpg"
                                    class="rounded-xl contrast-75 !h-72">
                                <div class="px-4 absolute bottom-1 w-full">
                                    <div class="px-2 bg-neutral-content w-max text-sm rounded">最佳专辑</div>
                                    <div class="flex justify-between text-white items-end">
                                        <div class="text-xl font-light">#{{ index + 1 }} {{ artist[0] }}</div>
                                        <div class="text-sm font-light">{{ artist[1] }}次记录</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else
                                :class="['flex justify-between px-4', index ===1 ? 'mt-2' : '', index ===4 ? 'mb-2' : '']">
                                <div class="text-sm mb-0.5">#{{ index + 1 }} {{ artist[0] }}</div>
                                <div class="text-sm">{{ artist[1] }}</div>
                            </div>
                        </div>


                    </div>
                    <div class="mt-2 bg-base-100 rounded-xl p-4">
                        <div class="flex justify-between">
                            <div class="flex-1">
                                <div class="bg-neutral-content w-max px-2 rounded">最佳新专辑</div>
                                <div class="font-light text-sm px-1">{{prefectMusic.album.newest[1]}}次记录</div>
                                <div class="text-sm px-1">{{prefectMusic.album.newest[0]}}</div>
                            </div>
                            <div class="w-16">
                                <div class="tile-item h-full">
                                    <img v-if="prefectMusic.album.newest[2]" :src="callCoverArtUrl(prefectMusic.album.newest[2])"
                                        class="rounded-xl contrast-75 !w-16">
                                    <img v-else src="/static/dist/img/album_null.jpg"
                                        class="rounded-xl contrast-75">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shadow-xl rounded-xl md:w-96 md:mr-4 md:mt-0 mt-4">
                    <div class="h-24 shadow-xl rounded-xl p-4 bg-accent" id="example-accent">
                        <div class="flex items-center">
                            <div>单曲</div>
                            <div class="badge badge-outline ml-2 text-xs">{{prefectMusic.track.rate}}</div>
                        </div>
                        <div class="mt-1 flex justify-between">
                            <div class="text-4xl">{{prefectMusic.track.count}}</div>
                            <div class="flex">
                                <svg t="1727701285115" class="fill-current w-8 h-8" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="1502" width="200" height="200">
                                    <path
                                        d="M160 608c-19.2 0-32 12.8-32 32v128c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-128c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v128c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-128c0-54.4 41.6-96 96-96z m320-320c-19.2 0-32 12.8-32 32v512c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-512c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v512c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-512c0-54.4 41.6-96 96-96z m320 320c-19.2 0-32 12.8-32 32v256c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-256c0-19.2-12.8-32-32-32h-64z m0-64h64c54.4 0 96 41.6 96 96v256c0 54.4-41.6 96-96 96h-64c-54.4 0-96-41.6-96-96v-256c0-54.4 41.6-96 96-96z"
                                        fill="#374E6B" p-id="1503"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div v-for="(artist, index) in prefectMusic.track_record" :key="'tra'+index">
                            <div v-if="index===0" class="tile-item h-full relative">
                                <img v-if="artist[2]" :src="callCoverArtUrl(artist[2])"
                                    class="rounded-t-xl contrast-75 !h-72">
                                <img v-else src="/static/dist/img/album_null.jpg"
                                    class="rounded-xl contrast-75 !h-72">
                                <div class="px-4 absolute bottom-1 w-full">
                                    <div class="px-2 bg-accent w-max text-sm rounded">最佳单曲</div>
                                    <div class="flex justify-between text-white items-end">
                                        <div class="text-xl font-light">#{{ index + 1 }} {{ artist[0] }}</div>
                                        <div class="text-sm font-light">{{ artist[1] }}次记录</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else
                                :class="['flex justify-between px-4', index ===1 ? 'mt-2' : '', index ===4 ? 'mb-2' : '']">
                                <div class="text-sm mb-0.5">#{{ index + 1 }} {{ artist[0] }}</div>
                                <div class="text-sm">{{ artist[1] }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 bg-base-100 rounded-xl p-4">
                        <div class="flex justify-between">
                            <div class="flex-1">
                                <div class="bg-accent w-max px-2 rounded">最佳新单曲</div>
                                <div class="font-light text-sm px-1">{{prefectMusic.track.newest[1]}}次记录</div>
                                <div class="text-sm px-1">{{prefectMusic.track.newest[0]}}</div>
                            </div>
                            <div class="w-16">
                                <div class="tile-item h-full">
                                    <img v-if="prefectMusic.track.newest[2]" :src="callCoverArtUrl(prefectMusic.track.newest[2])"
                                        class="rounded-xl contrast-75 !w-16">
                                    <img v-else src="/static/dist/img/album_null.jpg"
                                        class="rounded-xl contrast-75">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="font-medium text-xl mt-8 mb-2 underline-offset-4 underline">榜单</div>
            <div class="shadow-xl rounded-xl md:w-1/2 w-80 bg-base-100 mr-2 mb-4">
                <div class="m-4 font-medium">最热风格</div>
                <div id="hot-tag" class="h-64"></div>
            </div>
            <div class="shadow-xl rounded-xl md:w-1/2 w-80 bg-base-100 mr-2 mb-4">
                <div class="m-4 font-medium">音乐年代</div>
                <div id="year" class="h-64"></div>
            </div>
            <div class="shadow-xl rounded-xl md:w-1/2 w-80 bg-base-100 mr-2 mb-4">
                <div class="m-4 font-medium">平台分布</div>
                <div id="plat" class="h-64"></div>
            </div>
            <div class="shadow-xl rounded-xl md:w-1/2 w-80 bg-base-100 mr-2 mb-4">
                <div class="m-4 font-medium">收听时钟</div>
                <div id="listen" class="h-64"></div>
            </div>
            <div class="font-medium text-xl mt-4 mb-2 underline-offset-4 underline">快速事实</div>
            <div class="h-40 flex flex-col md:flex-row mx-2 mt-2 mb-4">
            <div class="shadow-xl rounded-xl md:w-72 w-80 bg-base-100 mr-2 p-4 h-full mb-4 md:mb-0">
                <div class="flex flex-col">
                    <div class="flex items-center">
                        <div>收听时间</div>
                        <div class="badge badge-outline text-xs font-light ml-2">{{ listeningStats.duration.growth_rate }}</div>
                    </div>
                    <div class="text-4xl mt-4 font-medium">
                        {{ listeningStats.duration.formatted }}
                    </div>
                    <div class="text-base-400 font-light mt-4 ml-2">
                        vs {{ listeningStats.duration.last_week }}
                    </div>
                </div>
            </div>
            <div class="shadow-xl rounded-xl md:w-72 bg-base-100 mr-2 p-4 h-full mb-4 md:mb-0">
                <div class="flex flex-col">
                    <div class="flex items-center">
                        <div>平均记录</div>
                        <div class="badge badge-outline text-xs font-light ml-2">{{ listeningStats.average.growth_rate }}</div>
                    </div>
                    <div class="text-4xl mt-4 font-medium">
                        {{ listeningStats.average.count }}
                    </div>
                    <div class="text-base-400 font-light mt-4 ml-2">
                        vs {{ listeningStats.average.last_week }}
                    </div>
                </div>
            </div>
            <div class="shadow-xl rounded-xl md:w-72 bg-base-100 mr-2 p-4 h-full mb-4 md:mb-0">
                <div class="flex flex-col">
                    <div class="flex">
                        <div>最活跃一天</div>
                        <div class="badge badge-outline text-xs font-light ml-2">{{ listeningStats.most_active.growth_rate }}</div>
                    </div>
                    <div class="mt-4 flex items-end">
                        <div class="text-4xl text-accent font-medium">
                            {{ listeningStats.most_active.count }}
                        </div>
                        <div class="text-md ml-2 font-medium">
                            {{ listeningStats.most_active.date }}
                        </div>
                    </div>
                    <div class="text-base-400 font-light mt-4 ml-2">
                        vs {{ listeningStats.most_active.last_week }}
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getCoverArtUrl} from '@/api/apiUrl/subsonic/serializers'

export default {
    name: "record",
    data() {
        return {
            currentTheme: localStorage.getItem('theme') || 'light',
            selectPeriod: 'week',
            week_record: {},
            prefectMusic: {'track':{'newest':[]},'album':{'newest':[]},'artist':{'newest':[]}},
            hotGenres: {},
            hotDecades: {},
            platformStats: {},
            listeningStats: {
                'duration': {
                    'total_seconds': 1620,
                    'formatted': "0小时",
                    'last_week': "0小时",
                    'growth_rate': "0%"
                },
                'average': {
                    'count': 1,
                    'last_week': 0,
                    'growth_rate': "0%"
                },
                'most_active': {
                    'count': 9,
                    'date': "Jan 10",
                    'last_week': 0,
                    'growth_rate': "0%"
                }
            }
        }
    },
    methods: {
        callCoverArtUrl(coverArt) {
            return getCoverArtUrl({coverArt})
        },
        oklchToHex(oklchString) {
            const culori = require('culori');

            const match = oklchString.match(/oklch\(([\d.]+)\s+([\d.]+)\s+([\d.]+)\)/);
            if (!match) {
                throw new Error('Invalid OKLCH string');
            }

            const [, l, c, h] = match.map(Number);

            // 使用culori将OKLCH转换为RGB
            const rgb = culori.converter('rgb')({mode: 'oklch', l, c, h});

            // 将RGB值限制在0-1范围内，然后转换为0-255范围的整数
            const clamp = (value) => Math.max(0, Math.min(1, value));
            const r = Math.round(clamp(rgb.r) * 255);
            const g = Math.round(clamp(rgb.g) * 255);
            const b = Math.round(clamp(rgb.b) * 255);

            // 将RGB转换为16进制
            const toHex = (value) => {
                const hex = value.toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            };

            return '#' + toHex(r) + toHex(g) + toHex(b);
        },
        draw() {
            // 从元素的计算样式中获取颜色
            let accentColor = window.getComputedStyle(document.getElementById('example-accent'))['backgroundColor']
            accentColor = this.oklchToHex(accentColor)
            let neutralColor = window.getComputedStyle(document.getElementById('example-neutral'))['backgroundColor']
            neutralColor = this.oklchToHex(neutralColor)
            
            // 先销毁旧的图表实例
            const weeklyChart = this.$echarts.getInstanceByDom(document.getElementById('weekly'))
            if (weeklyChart) {
                weeklyChart.dispose()
            }
            
            // 创建新的图表实例
            const myChart = this.$echarts.init(document.getElementById('weekly'))
            const option = {
                legend: {},
                color: [neutralColor, accentColor],
                tooltip: {},
                dataset: {
                    source: this.week_record.record
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        show: false
                    }
                },
                yAxis: [{
                    type: "value",
                    show: false
                }],
                series: [
                    {type: 'bar'},
                    {type: 'bar'}
                ]
            };
            myChart.setOption(option)
        },
        drawHotTag() {
            // 先销毁旧的图表实例
            const hotTagChart = this.$echarts.getInstanceByDom(document.getElementById('hot-tag'))
            if (hotTagChart) {
                hotTagChart.dispose()
            }
            
            const myChart = this.$echarts.init(document.getElementById('hot-tag'))
            const option = {
                color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: this.hotGenres.genres
                },
                toolbox: {
                    feature: {
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%', 
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.hotGenres.date_list
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: this.hotGenres.genres.map((genre, index) => ({
                    name: genre,
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    lineStyle: {
                        width: 0
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.8,
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'][index]
                            },
                            {
                                offset: 1,
                                color: ['#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5'][index]
                            }
                        ])
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: this.hotGenres.daily_genres.map(day => day[index])
                }))
            };
            myChart.setOption(option)
        },
        drawYear() {
            // 先销毁旧的图表实例
            const yearChart = this.$echarts.getInstanceByDom(document.getElementById('year'))
            if (yearChart) {
                yearChart.dispose()
            }
            
            let accentColor = window.getComputedStyle(document.getElementById('example-accent'))['backgroundColor']
            accentColor = this.oklchToHex(accentColor)
            const myChart = this.$echarts.init(document.getElementById('year'))
            const option = {
                title: {
                    text: ''
                },
                color: [accentColor],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    show: true
                },
                yAxis: {
                    type: 'category',
                    data: this.hotDecades.decades
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data: this.hotDecades.daily_decades
                    }
                ]
            };
            myChart.setOption(option)
        },
        drawPlat() {
            // 先销毁旧的图表实例
            const platChart = this.$echarts.getInstanceByDom(document.getElementById('plat'))
            if (platChart) {
                platChart.dispose()
            }
            
            const myChart = this.$echarts.init(document.getElementById('plat'))
            const option = {
                legend: {
                    top: 'bottom'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c} ({d}%)'
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: {show: true}
                    }
                },
                series: [
                    {
                        name: '平台分布',
                        type: 'pie',
                        radius: [40, 100],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: 'bold'
                            }
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.platformStats
                    }
                ]
            };
            myChart.setOption(option)
        },
        drawListen() {
            // 先销毁旧的图表实例
            const listenChart = this.$echarts.getInstanceByDom(document.getElementById('listen'))
            if (listenChart) {
                listenChart.dispose()
            }
            
            const myChart = this.$echarts.init(document.getElementById('listen'))
            const hours = [
                '00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
                '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
                '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
                '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
            ];
            const data = this.listeningClock.listening_count
            const option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: function(params) {
                        return params[0].name + '<br/>' + 
                               '收听次数: ' + params[0].value;
                    }
                },
                xAxis: {
                    type: 'category',
                    data: hours,
                    axisLabel: {
                        interval: 3
                    }
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [{
                    data: data,
                    type: 'line',
                    smooth: true,
                    areaStyle: {
                        opacity: 0.8
                    },
                    lineStyle: {
                        width: 0
                    },
                    itemStyle: {
                        opacity: 0
                    }
                }]
            };
            myChart.setOption(option)
        },
        fetchWeekRecord() {
            this.$api.Task.WeekRecord({period: this.selectPeriod}).then((res) => {
                this.week_record = res.data
                this.draw()
            })
        },
        fetchPerfectMusic() {
            this.$api.Task.getPerfectMusic({period: this.selectPeriod}).then((res) => {
                this.prefectMusic = res.data
            })
        },
        fetchHotGenres() {
            this.$api.Task.getHotGenres({period: this.selectPeriod}).then((res) => {
                this.hotGenres = res.data
                this.drawHotTag()
            })
        },
        fetchHotDecades() {
            this.$api.Task.getHotDecades({period: this.selectPeriod}).then((res) => {
                this.hotDecades = res.data
                this.drawYear()
            })
        },
        fetchPlatformStats() {
            this.$api.Task.getPlatformStats({period: this.selectPeriod}).then((res) => {
                this.platformStats = res.data
                this.drawPlat()
            })
        },
        fetchListeningStats() {
            this.$api.Task.getListeningStats({period: this.selectPeriod}).then((res) => {
                this.listeningStats = res.data
            })
        },
        fetchListeningClock() {
            this.$api.Task.getListeningClock({period: this.selectPeriod}).then((res) => {
                this.listeningClock = res.data
                this.drawListen()
            })
        },
        handleSelectPeriod(period) {
            this.selectPeriod = period
            this.fetchWeekRecord()
            this.fetchPerfectMusic()
            this.fetchHotGenres()
            this.fetchHotDecades()
            this.fetchPlatformStats()
            this.fetchListeningStats()
            this.fetchListeningClock()
        }
    },
    mounted() {
        this.fetchWeekRecord()
        this.fetchPerfectMusic()
        this.fetchHotGenres()
        this.fetchHotDecades()
        this.fetchPlatformStats()
        this.fetchListeningStats()
        this.fetchListeningClock()
    },
    created() {
    }
}
</script>

<style scoped>
.tile-item {
    width: 100%;
}

.tile-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.body {
    margin: 0;
    background: repeating-linear-gradient(
    -45deg, /* 斜线的角度 */
    #f0f0f0, /* 更浅的第一种颜色 */
    #f0f0f0 10px, /* 第一种颜色延伸的距离 */
    #e0e0e0 10px, /* 更浅的第二种颜色开始的地方 */
    #e0e0e0 20px /* 第二种颜色延伸的距离，同时也定义了条纹的总宽度 */
  );
  background-attachment: fixed; /* 固定背景 */
  background-size: 100% 100%; /* 确保背景覆盖整个元素 */
}
.body-dark {
    margin: 0;
    background: repeating-linear-gradient(
    -45deg, /* 斜线的角度 */
    #333,   /* 第一种颜色（起点颜色） */
    #333 10px, /* 第一种颜色延伸的距离 */
    #666 10px, /* 第二种颜色（终点颜色）开始的地方 */
    #666 20px /* 第二种颜色延伸的距离，同时也定义了条纹的总宽度 */
  );
  background-attachment: fixed; /* 固定背景 */
  background-size: 100% 100%; /* 确保背景覆盖整个元素 */
}
</style>
