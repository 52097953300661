import {AudioController} from '../../views/library/player/audio'
import {shuffle, trackListEquals, shuffled} from '../../common/util'

const mediaSession = navigator.mediaSession
const audio = new AudioController()
function persistQueue(state) {
    localStorage.setItem('queue', JSON.stringify(state.queue))
    localStorage.setItem('queueIndex', state.queueIndex.toString())
}

const common = {
    state: {
        // fullPath: '/Users/macbookair/Music/my_music/music', // todo test
        fullPath: '/app/media',
        checkedIds: [],
        checkedData: [],
        defaultTableHeight: 800,
        userRole: '',
        username: '',
        isPlaying: false,
        queue: [],
        queueIndex: -1,
        currentTime: 0,
        duration: 0,
        repeat: localStorage.getItem('player.repeat') !== 'false',
        shuffle: localStorage.getItem('player.shuffle') === 'true',
        hasMsg: false,
        searchTerm: '',
        sortedField: localStorage.getItem('sortedField') ? JSON.parse(localStorage.getItem('sortedField')) : [],
        searchWord: localStorage.getItem('searchWord') || '',
        volume: localStorage.getItem('player.volume') ? parseFloat(localStorage.getItem('player.volume')) : 1,
    },
    mutations: {
        setDefaultTableHeight: (state, val) => {
            state.defaultTableHeight = val
        },
        setUserRole: (state, val) => {
            state.userRole = val
        },
        setUsername: (state, val) => {
            state.username = val
        },
        setFullPath: (state, val) => {
            state.fullPath = val
        },
        setHasMsg: (state, val) => {
            state.hasMsg = val
        },
        setPlaying(state) {
            state.isPlaying = true
            if (mediaSession) {
                mediaSession.playbackState = 'playing'
            }
        },
        toggleRepeat(state) {
            state.repeat = !state.repeat
            localStorage.setItem('player.repeat', state.repeat)
        },
        toggleShuffle(state) {
            state.shuffle = !state.shuffle
            localStorage.setItem('player.shuffle', state.shuffle)
        },
        setPaused(state) {
            state.isPlaying = false
            if (mediaSession) {
                mediaSession.playbackState = 'paused'
            }
        },
        setCurrentTime(state, value) {
            state.currentTime = value
        },
        setDuration(state, value) {
            if (isFinite(value)) {
                state.duration = value
            }
        },
        setStreamTitle(state, value) {
            state.streamTitle = value
        },
        setQueue(state, queue) {
            state.queue = queue
            state.queueIndex = -1
            persistQueue(state)
        },
        setQueueIndex(state, index) {
            if (state.queue.length === 0) {
                return
            }
            index = Math.max(0, index)
            index = index < state.queue.length ? index : 0
            state.queueIndex = index
            persistQueue(state)
            state.scrobbled = false
            const track = state.queue[index]
            state.duration = track.duration
            const next = (index + 1) % state.queue.length
            audio.setBuffer(state.queue[next].url)
        },
        setNextInQueue(state, tracks) {
            state.queue.splice(state.queueIndex + 1, 0, ...tracks)
            // persistQueue(state)
        },
        addToQueue(state, tracks) {
            state.queue.push(...tracks)
            // persistQueue(state)
        },
        setVolume(state, value) {
            state.volume = value
            localStorage.setItem('player.volume', String(value))
        },
        setCheckedIds(state, value) {
            state.checkedIds = value
        },
        setCheckedData(state, value) {
            state.checkedData = value
        },
        clearQueue(state) {
            if (state.queueIndex >= 0) {
                state.queue = [state.queue[state.queueIndex]]
                state.queueIndex = 0
                persistQueue(state)
            }
        },
        shuffleQueue(state) {
            if (state.queue.length > 0) {
                state.queue = shuffled(state.queue, state.queueIndex)
                state.queueIndex = 0
                persistQueue(state)
            }
        },
        setSearchTerm(state, term) {
            state.searchTerm = term;
        },
        setSortedField(state, value) {
            localStorage.setItem('sortedField', JSON.stringify(value))
            state.sortedField = value
        },
        setSearchWord(state, value) {
            localStorage.setItem('searchWord', value)
            state.searchWord = value
        }
    },
    actions: {
        async playPause({ state, dispatch }) {
            return state.isPlaying ? dispatch('pause') : dispatch('resume')
        },
        async resume({ commit }) {
            commit('setPlaying')
            await audio.resume()
        },
        setVolume({ commit }, value) {
            audio.setVolume(value)
            commit('setVolume', value)
        },
        async pause({ commit }) {
            audio.pause()
            commit('setPaused')
        },
        toggleRepeat({ commit }) {
            commit('toggleRepeat')
        },
        toggleShuffle({ commit }) {
            commit('toggleShuffle')
        },
        seek({ state }, value) {    
            if (isFinite(state.duration)) {
                audio.seek(state.duration * value)
            }
        },
        async next({ commit, state, getters }) {
            commit('setQueueIndex', state.queueIndex + 1)
            commit('setPlaying')
            const track = getters.track
            console.log('Next Track Data:', track)
            await audio.changeTrack({
                ...track,
                title: track.title || track.name,
                artist: track.artist || track.artistName,
                album: track.album || track.albumName,
                artwork: track.image || track.artwork || track.coverArt
            })
        },
        async previous({ commit, state, getters }) {
            commit('setQueueIndex', audio.currentTime() > 3 ? state.queueIndex : state.queueIndex - 1)
            commit('setPlaying')
            const track = getters.track
            console.log('Previous Track Data:', track)
            await audio.changeTrack({
                ...track,
                title: track.title || track.name,
                artist: track.artist || track.artistName,
                album: track.album || track.albumName,
                artwork: track.image || track.artwork || track.coverArt
            })
        },
        async resetQueue({ commit, getters }) {
            commit('setQueueIndex', 0)
            commit('setPaused')
            const track = getters.track
            await audio.changeTrack({
                ...track,
                title: track.title || '未知歌曲',
                artist: track.artist || '未知艺术家',
                album: track.album || '未知专辑',
                artwork: track.image,
                paused: true
            })
        },
        async playTrackList({ commit, state, getters }, { tracks, index }) {
            if (index === null) {
                index = state.shuffle ? Math.floor(Math.random() * tracks.length) : 0
            }
            if (state.shuffle) {
                tracks = [...tracks]
                shuffle(tracks, index)
                index = 0
            }
            if (!trackListEquals(state.queue, tracks)) {
                commit('setQueue', tracks)
            }
            commit('setQueueIndex', index)
            commit('setPlaying')
            const track = getters.track
            await audio.changeTrack({
                ...track,
                title: track.title,
                artist: track.artist,
                album: track.album,
                artwork: track.image
            })
        }
    }
}

export default common
