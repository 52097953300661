<template>
    <div>
        <div class="pt-2 mx-8">
            <div class="collapse bg-base-100 mb-2" v-if="detailData.mode_success.length > 0">
                <input type="checkbox" v-model="isModeSuccess" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>文件hash重复</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{detailData.mode_success_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="flex justify-end">
                        <button class="btn btn-neutral btn-active btn-sm" @click="handleRemoveFile('', 'hash')">
                            全部删除
                        </button>
                    </div>
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>文件路径</th>
                                    <th>原因</th>
                                    <th class="w-24">操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.mode_success" :key="'mode' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.path}}</td>
                                    <td>{{item.reason}}</td>
                                    <td>
                                        <button class="btn btn-sm btn-error btn-outline" @click="handleRemoveFile(item.path, 'simple')">删除</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.success.length > 0">
                <input type="checkbox" v-model="isSuccess" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>文件名称重复</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{detailData.success_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="flex justify-end">
                        <button class="btn btn-neutral btn-active btn-sm" @click="handleRemoveFile('', 'name')">
                            全部删除
                        </button>
                    </div>
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>文件路径</th>
                                    <th>原因</th>
                                    <th class="w-24">操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.success" :key="'mode' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.path}}</td>
                                    <td>{{item.reason}}</td>
                                    <td>
                                        <button class="btn btn-sm btn-error btn-outline" @click="handleRemoveFile(item.path, 'simple')">删除</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'log-detail',
        props: {
            detailData: { type: Object, required: true }
        },
        data() {
            return {
                id: this.$route.params.id,
                isOpen: true,
                timer: null
            }
        },
        computed: {
            isSuccess() {
                return this.detailData.success.length > 0
            },
            isFail() {
                return this.detailData.fail.length > 0
            },
            isModeSuccess() {
                return this.detailData.mode_success.length > 0
            },
            isMissAA() {
                return this.detailData.miss_artist_album.length > 0
            },
            isMissArtist() {
                return this.detailData.miss_artist.length > 0
            },
            isMissAlbum() {
                return this.detailData.miss_album.length > 0
            },
            isMissTag() {
                return this.detailData.miss_tag.length > 0
            },
            isRunning() {
                return this.detailData.running.length > 0
            },
            isSkip() {
                return this.detailData.skip.length > 0
            }
        },
        mounted() {
            // this.handlePolling()
        },
        beforeDestroy() {
            this.setTimerNull() // 组件销毁前清空定时器
        },
        methods: {
            handleRemoveFile(path, mode) {
                this.$bkInfo({
                    title: '确认要删除文件？',
                    confirmLoading: true,
                    confirmFn: () => {
                        try {
                            this.isLoading = true
                            this.$api.Task.deleteFiles(
                                {
                                    'log_id': this.id,
                                    'paths': [path],
                                    'mode': mode
                                }).then((res) => {
                                this.isLoading = false
                                if (res.result) {
                                    this.$myMsg.notify({
                                        title: '删除成功！',
                                        type: 'success',
                                        time: 2000,
                                    })
                                    this.$parent.fetchDetails()
                                } else {
                                    this.$myMsg.notify({
                                        title: '删除失败！',
                                        content: res.message,
                                        type: 'error',
                                        time: 2000,
                                    })
                                }
                            })
                            return true
                        } catch (e) {
                            console.warn(e)
                            return false
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
