<template>
    <div class="relative">
        <div class="flex flex-wrap">
            <div ref="file-section" class="file-section bg-base-100 shadow-xl rounded-xl">
                <file-tree ref="file-tree"></file-tree>
            </div>
            <div ref="edit-section" class="edit-section bg-base-100 shadow-xl rounded-xl !hidden md:!block">
                <transition name="bk-slide-fade-left">
                    <music-meta-data v-show="isShowEdit && checkedIds.length === 0"
                                     :music-info="musicInfo" :reload-img="reloadImg" :drawer-mode="'page'"
                                     class="w-[95%] md:w-[500px] pl-8 pt-4">
                    </music-meta-data>
                </transition>
                <transition name="bk-slide-fade-left">
                    <div class="flex">
                        <batch-action></batch-action>
                    </div>
                </transition>
            </div>
            <div ref="resource-section" class="resource-section bg-base-100 shadow-xl rounded-xl !hidden md:!block">
                <transition name="bk-slide-fade-left" v-if="!isShowResourceId3">
                    <resource v-show="fadeShowDetail" :song-list="SongList" :section3-loading="section3Loading"></resource>
                </transition>
                <transition name="bk-slide-fade-left">
                    <div>
                        <div v-if="showId3ListLoading" class="flex justify-center">
                            <span class="loading loading-infinity loading-lg"></span>
                        </div>
                        <multi-meta-data v-show="showId3List" :file-id3-list="fileID3List" :files-number="filesNumber" :folder-number="folderNumber"></multi-meta-data>
                    </div>
                </transition>
                <div v-show="!fadeShowDetail && !showTranslation && !isShowResourceId3 && !showId3List"
                     style="width: 90%;height: 90%; margin: 50px 20px 20px 50px;">
                    <bk-image fit="contain" :src="'/static/dist/img/music_null-cutout.png'"
                              v-if="!isHiddenBGP"
                              style="width: 100%;height: 98%;">
                    </bk-image>
                </div>
            </div>
            <div class="drawer">
                <input id="edit" type="checkbox" class="drawer-toggle" v-model="isShowEdit2" />
                <div class="drawer-content">
                </div>
                <div class="drawer-side">
                    <label for="edit" aria-label="close sidebar" class="drawer-overlay"></label>
                    <div class="p-4 min-h-full bg-base-100 text-base-content w-full md:w-96">
                        <div class="flex justify-between items-center mb-4">
                            <div class="text-xl font-medium">元数据详情</div>
                        </div>
                        <div>
                            <music-meta-data v-show="isShowEdit2" :loading='section2Loading' :music-info="musicInfo" :reload-img="reloadImg" :drawer-mode="'drawer'">
                            </music-meta-data>
                        </div>
                    </div>
                </div>
            </div>
            <div class="drawer drawer-end">
                <input id="resource-id3" type="checkbox" class="drawer-toggle" v-model="isShowResourceId3" />
                <div class="drawer-content">
                </div>
                <div class="drawer-side">
                    <label for="resource-id3" aria-label="close sidebar" class="drawer-overlay"></label>
                    <div class="p-4 min-h-full bg-base-100 text-base-content w-80 md:w-[800px]">
                        <resource :song-list="SongList" :section3-loading="section3Loading"></resource>
                    </div>
                </div>
            </div>
            <div class="drawer drawer-end">
                <input id="trans-lrc" type="checkbox" class="drawer-toggle" v-model="showTranslation" />
                <div class="drawer-content">
                </div>
                <div class="drawer-side">
                    <label for="trans-lrc" aria-label="close sidebar" class="drawer-overlay"></label>
                    <div class="p-4 min-h-full bg-base-100 text-base-content w-80 md:w-[800px]">
                        <div style="display: flex;height: 100%;">
                            <bk-icon type="arrows-left-shape" @click="handleCopy('lyric_tran',translationText)"
                                     style="margin-right: 5px;margin-left: 15px;margin-top: 50%;cursor: pointer;"></bk-icon>
                            <div style="width: 100%;height: 100%;">
                                <bk-input :clearable="true" v-model="translationText" type="textarea" :rows="50"
                                          style="height: 100%;">
                                </bk-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <ul class="menu menu-horizontal bg-base-200 rounded-box mt-6 absolute bottom-4 left-4 z-10 md:!hidden">-->
<!--            <li>-->
<!--                <a class="tooltip" data-tip="Home" @click="showFileSection">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>-->
<!--                </a>-->
<!--            </li>-->
<!--        </ul>-->
    </div>
</template>
<script>
import FileTree from './fileTree'
import MusicMetaData from './musicMetaData'
import BatchAction from './batchAction'
import Resource from './resource'
import MultiMetaData from './multiMetaData'
import {mapGetters} from 'vuex'

export default {
    components: {
        MusicMetaData,
        FileTree,
        BatchAction,
        Resource,
        MultiMetaData
    },
    data() {
        return {
            theme: document.documentElement.getAttribute('data-theme'),
            fullPath: '',
            fileName: '',
            resource: localStorage.getItem('resource') ? localStorage.getItem('resource') : 'netease',
            translationText: '',
            baseMusicInfo: {},
            isSaveLyricsFile: false,
            isSaveAlbumCover: false,
            isImportLyricsFile: false,
            genreInputList: [],
            languageInputList: [],
            albumArtistInputList: [],
            musicInfo: {
            },
            musicInfoManual: {},
            fadeShowDir: false,
            showId3List: false,
            showId3ListLoading: false,
            isLoading: false,
            SongList: [],
            reloadImg: true,
            test: true,
            isSkipTag: true,
            isFolderAlbum: false,
            albumArtistList: [],
            isShowEdit: false,
            isShowEdit2: false,
            selectAutoMode: 'normal',
            sourceList: [],
            fileID3List: [],
            fileID3ListCopy: [],
            modifyList: ['artist', 'album', 'album_img', 'year', 'lyrics'],
            modifyListL: ['title', 'artist', 'album'],
            relaceTextList: [],
            tidyFormData: {
                root_path: '/app/media/',
                dir_list: [{'value': 'artist'}, {'value': 'album'}]
            },
            isOpenDetail: localStorage.getItem('isOpenDetail') ? localStorage.getItem('isOpenDetail') : true,
            batchSettingFormData: {
                'isOpenDetail': localStorage.getItem('isOpenDetail') !== 'false',
                'isDepth': localStorage.getItem('isDepth') === 'true',
                'limit': localStorage.getItem('limit') ? localStorage.getItem('limit') : 100
            },
            sortedListOrder: ['forward', 'reverse'],
            nowSortedOrder: 'none',
            nowSortedKey: '',
            isShowResourceId3: false,
            fadeShowDetail: false,
            showTranslation: false,
            section3Loading: false,
            section2Loading: false
        }
    },
    computed: {
        ...mapGetters(['geFullPath', 'sortedField', 'searchWord']),
        filePath: {
            get() {
                return this.geFullPath
            },
            set(value) {
                this.$store.commit('setFullPath', value)
            }
        },
        checkedIds: {
            get() {
                return this.$store.state.common.checkedIds
            },
            set(value) {
                this.$store.commit('setCheckedIds', value)
            }
        },
        checkedData: {
            get() {
                return this.$store.state.common.checkedData
            },
            set(value) {
                this.$store.commit('setCheckedData', value)
            }
        },
        filesNumber: {
            get() {
                return this.checkedData.filter(item => ['icon-script-file', 'icon-script-files'].indexOf(item.icon) !== -1).length
            }
        },
        folderNumber: {
            get() {
                return this.checkedData.filter(item => item.icon === 'icon-folder').length
            }
        },
        isHiddenBGP: {
            get() {
                return window.localStorage.getItem('isHiddenBGP') === 'true'
            },
            set(value) {
                window.localStorage.setItem('isHiddenBGP', value ? 'true' : 'false')
            }
        }
    },
    created() {
    },
    methods: {
        // 点击树的节点后展示详情
        showEditPage(parentPath, nodeName) {
            this.isShowEdit = true
            this.showId3List = this.checkedIds.length > 0
            this.musicInfo = this.baseMusicInfo
            this.fileName = nodeName
            this.section2Loading = true
            this.$api.Task.musicId3({'file_path': parentPath, 'file_name': nodeName}).then((res) => {
                if (res.result) {
                    this.section2Loading = false
                    this.musicInfo = res.data
                    this.isSaveLyricsFile = false
                    this.isImportLyricsFile = false
                    this.isSaveAlbumCover = false
                    this.genreInputList = this.musicInfo.genre ? [this.musicInfo.genre] : []
                    this.languageInputList = this.musicInfo.language ? [this.musicInfo.language] : []
                    this.albumArtistInputList = this.musicInfo.albumartist ? [this.musicInfo.albumartist] : []
                    this.albumArtistList = this.parseAlbumArtistList(this.musicInfo.artist)
                    this.musicInfo.files1 = [
                        {
                            name: 'cover.png',
                            status: 'done',
                            url: this.musicInfo.artwork
                        }
                    ]
                } else {
                    this.$myMsg.notify({
                        title: '修改失败！',
                        content: res.message,
                        type: 'error',
                        time: 5000,
                    })
                }
            })
        },
        showEditPageDrawer(parentPath, nodeName) {
            this.isShowEdit = false
            // this.showId3List = this.checkedIds.length > 0
            this.musicInfo = this.baseMusicInfo
            this.fileName = nodeName
            this.section2Loading = true
            this.isShowEdit2 = true
            this.$api.Task.musicId3({'file_path': parentPath, 'file_name': nodeName}).then((res) => {
                if (res.result) {
                    this.section2Loading = false
                    this.musicInfo = res.data
                    this.isSaveLyricsFile = false
                    this.isImportLyricsFile = false
                    this.isSaveAlbumCover = false
                    this.genreInputList = this.musicInfo.genre ? [this.musicInfo.genre] : []
                    this.languageInputList = this.musicInfo.language ? [this.musicInfo.language] : []
                    this.albumArtistInputList = this.musicInfo.albumartist ? [this.musicInfo.albumartist] : []
                    this.albumArtistList = this.parseAlbumArtistList(this.musicInfo.artist)
                    this.musicInfo.files1 = [
                        {
                            name: 'cover.png',
                            status: 'done',
                            url: this.musicInfo.artwork
                        }
                    ]
                } else {
                    this.$myMsg.notify({
                        title: '修改失败！',
                        content: res.message,
                        type: 'error',
                        time: 5000,
                    })
                }
            })
        },

        // 处理专辑艺术家
        parseAlbumArtistList(artists) {
            let ret = []
            if (artists) {
                let artistArray = []
                if (artists.indexOf(',') > -1) {
                    artistArray = artists.split(',')
                } else if (artists.indexOf('/') > -1) {
                    artistArray = artists.split('/')
                } else if (artists.indexOf('&') > -1) {
                    artistArray = artists.split('&')
                } else if (artists.indexOf('、') > -1) {
                    artistArray = artists.split('、')
                } else if (artists.indexOf('，') > -1) {
                    artistArray = artists.split('，')
                } else {
                    artistArray = []
                }
                artistArray.push(artists)
                ret = artistArray.map((item) => {
                    return {
                        'id': item,
                        'name': item
                    }
                })
            }
            return ret
        },
        handleCopy(k, v) {
            if (k === 'lyric') {
                this.resource = localStorage.getItem('resource') ? localStorage.getItem('resource') : 'netease'
                const resurce = this.resource !== 'smart_tag' ? this.resource : v.resource
                this.$api.Task.fetchLyric({'song_id': v.id, 'resource': resurce}).then((res) => {
                    if (res.result) {
                        if (res.data !== '') {
                            this.musicInfo['lyrics'] = res.data
                            this.handleCopy('composer', this.musicInfo['lyrics'])
                        } else {
                            this.$myMsg.notify({
                                title: '未找到歌词！',
                                type: 'error',
                                time: 2000,
                            })
                        }
                    } else {
                        this.$myMsg.notify({
                            title: '未找到歌词！',
                            type: 'error',
                            time: 2000,
                        })
                    }
                })
            } else if (k === 'composer') {
                this.$api.Task.extractLyric({'lyc': v}).then((res) => {
                    if (res.result) {
                        if (res.data !== '') {
                            this.musicInfo['composer'] = res.data.composer
                            this.musicInfo['lyricist'] = res.data.lyricist
                        } else {
                            console.log()
                        }
                    } else {
                        console.log()
                    }
                })
            }
            else if (k === 'album_img') {
                this.musicInfo[k] = ''
                this.musicInfo['artwork'] = v
                this.musicInfo['files1'] = [
                    {
                        name: 'new.png',
                        status: 'done',
                        url: v
                    }
                ]
                this.reloadImg = false
                this.$nextTick(() => {
                    this.reloadImg = true
                })
            } else if (k === 'lyric_tran') {
                this.musicInfo['lyrics'] = v
            } else {
                this.musicInfo[k] = v
            }
        },
        copyAll(item) {
            this.handleCopy('title', item.name)
            this.handleCopy('year', item.year)
            this.handleCopy('lyric', item)
            this.handleCopy('album', item.album)
            this.handleCopy('artist', item.artist)
            this.handleCopy('album_img', item.album_img)
            this.handleCopy('tracknumber', item.tracknumber)
            this.handleCopy('discnumber', item.discnumber)
            this.handleCopy('totaltracks', item.totaltracks)
            this.handleCopy('totaldiscs', item.totaldiscs)
        },
        nodeExpandedOne(node, expanded) {
            console.log(node, expanded)
        },
        handleSearchFile() {
            this.$refs['file-tree'].handleSearchFile()
        },
        // 单个保存音乐信息
        handleClick() {
            // 刷新目录
            if (this.fileName !== this.musicInfo.filename) {
                this.fileName = this.musicInfo.filename
                this.handleSearchFile()
            }
        },
        fetchFileID3FolderList(mode) {
            const path = require('path')
            const fileName = path.basename(this.filePath)
            const directoryName = path.dirname(this.filePath);
            const batchSettingFormData = {
                'isOpenDetail': localStorage.getItem('isOpenDetail') !== 'false',
                'isDepth': localStorage.getItem('isDepth') === 'true',
                'limit': localStorage.getItem('limit') ? localStorage.getItem('limit') : 100
            }
            if (!batchSettingFormData.isOpenDetail) {
                return
            }
            let depth = 1
            if (batchSettingFormData.isDepth) {
                depth = -1
            }
            this.showTranslation = false
            this.fadeShowDetail = false
            if (this.checkedIds.length === 0 && this.fileID3List.length !== 0) {
                this.fileID3List = []
            }
            this.showId3ListLoading = true
            this.$api.Task.fileID3List({
                'file_full_path': directoryName,
                'select_data': [{
                    'icon': 'icon-folder',
                    'name': fileName,
                    'title': fileName,
                }],
                'mode': 'all',
                'limit': batchSettingFormData.limit,
                'depth': depth,
                'sorted_fields': this.sortedField,
                'search_word': this.searchWord
            }).then((res) => {
                if (res.result) {
                    this.showId3ListLoading = false
                    if (res.data.length > 0) {
                        this.fileID3List = res.data
                        this.showId3List = true
                    }
                } else {
                    if (res.message.status === 401) {
                        return
                    }
                    this.$myMsg.notify({
                        title: '查询失败',
                        content: res.message,
                        type: 'error',
                        time: 2000,
                    })
                }
            })
        },
        removeFileID3List(id) {
            this.fileID3List = this.fileID3List.filter((item) => item.id !== id)
        },
        deleteFileID3List() {
            this.fileID3List = []
        },
        toggleLock(mode) {
            if (!this.musicInfo.title) {
                this.$myMsg.notify({
                    title: '标题不能为空！',
                    type: 'error',
                    time: 2000,
                })
                return
            }
            console.log(mode)
            if (mode === 'page') {
                this.showTranslation = false
                this.isShowResourceId3 = false
                this.fadeShowDetail = true
            } else {
                this.showTranslation = false
                this.fadeShowDetail = false
                this.isShowResourceId3 = true
            }
            if (this.filePath.endsWith('/')) {
                this.fullPath = this.filePath + this.fileName
            } else {
                this.fullPath = this.filePath + '/' + this.fileName
            }
            this.section3Loading = true
            this.$api.Task.fetchId3Title({
                title: this.musicInfo.title,
                artist: this.musicInfo.artist,
                album: this.musicInfo.album,
                full_path: this.fullPath,
                resource: localStorage.getItem('resource') ? localStorage.getItem('resource') : 'netease'
            }).then((res) => {
                this.SongList = res.data
                this.section3Loading = false
            })
        },
        // 打开单个编辑抽屉
        handleEdit2(item) {
            console.log('open', item)
            this.musicInfo = item
            this.isSaveLyricsFile = false
            this.isImportLyricsFile = false
            this.isSaveAlbumCover = false
            this.genreInputList = this.musicInfo.genre ? [this.musicInfo.genre] : []
            this.languageInputList = this.musicInfo.language ? [this.musicInfo.language] : []
            this.albumArtistInputList = this.musicInfo.albumartist ? [this.musicInfo.albumartist] : []
            this.albumArtistList = this.parseAlbumArtistList(this.musicInfo.artist)
            this.musicInfo.files1 = [
                {
                    name: 'cover.png',
                    status: 'done',
                    url: this.musicInfo.artwork
                }
            ]
            this.fileName = this.musicInfo.filename
            this.isShowEdit2 = true
        },
        translation() {
            if (!this.musicInfo.lyrics) {
                this.$myMsg.notify({
                    title: '歌词不能为空！',
                    type: 'error',
                    time: 2000,
                })
            }
            this.fadeShowDetail = false
            this.isShowResourceId3 = false
            this.showTranslation = true
            this.$api.Task.translationLyc({
                lyc: this.musicInfo.lyrics
            }).then((res) => {
                this.showTranslation = true
                this.translationText = res.data
            })
        },
        showFileSection() {
            this.$refs['file-section'].style.setProperty('display', 'block', 'important')
            this.$refs['edit-section'].style.display = 'none'
            this.$refs['resource-section'].style.display = 'none'
        },
        showEditSection() {
            this.$refs['edit-section'].style.setProperty('display', 'block', 'important')
            this.$refs['file-section'].style.setProperty('display', 'none', 'important')
            this.$refs['resource-section'].style.display = 'none'
        },
        showResourceSection() {
            this.$refs['file-section'].style.display = 'none'
            this.$refs['edit-section'].style.display = 'none'
            this.$refs['resource-section'].style.setProperty('display', 'block', 'important')
        }
    }
}
</script>
<style lang="postcss">
.bk-table-header .custom-header-cell {
    color: inherit;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
}

.music-item {
    cursor: pointer;
}

.music-item:hover {
    color: #1facdd;
}

.label1 {
    width: 80px;
}

.parent {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    place-items: center;
    margin-bottom: 15px;
}

.title2 {
    font-weight: 500;
}

.song-card {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E2E2E2;
}

.song-card:hover {
    background: #E2E2E2;
}

.add-button {
    width: 24px;
    height: 24px;
    line-height: 20px;
    display: inline-block;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 12px;
    color: rgb(97, 97, 97);
    text-align: center;
    cursor: pointer;
}

.delete-button {
    width: 24px;
    height: 24px;
    line-height: 20px;
    display: inline-block;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 12px;
    color: rgb(63, 63, 63);
    text-align: center;
    cursor: pointer;
}

@media (max-width: 430px) {
    /* 在屏幕宽度小于400像素时应用的CSS规则 */
    .file-section {
        height: calc(100vh - 70px);
        overflow: scroll;
        width: 100vh;
        /*border: 1px solid #173769;*/
        /*border-radius: 20px;*/
    }

    .edit-section {
        height: calc(100vh - 70px);
        overflow: scroll;
        width: 100vh;
        /*border: 1px solid #173769;*/
        /*border-radius: 20px;*/
    }

    .resource-section {
        height: calc(100vh - 70px);
        width: 100vh;
        flex: 1;
        overflow: scroll;
        /*border: 1px solid #173769;*/
        /*border-radius: 20px;*/
    }
}

@media (min-width: 430px) {
    /* 在屏幕宽度大于400像素时应用的CSS规则 */
    .file-section {
        height: calc(100vh - 90px);
        overflow: scroll;
        min-width: 380px;
        /*border: 1px solid #173769;*/
        margin: 10px 0 10px 10px;
        /*border-radius: 20px;*/
    }

    .edit-section {
        height: calc(100vh - 90px);
        overflow: scroll;
        /*border: 1px solid #173769;*/
        margin: 10px 10px 10px 10px;
        /*border-radius: 20px;*/
    }

    .resource-section {
        height: calc(100vh - 90px);
        min-width: 400px;
        flex: 1;
        overflow: scroll;
        /*border: 1px solid #173769;*/
        margin: 10px 10px 10px 0;
        /*border-radius: 20px;*/
    }
}

.bk-form-checkbox {
    margin-right: 10px;
}

/*.success {*/
/*    color: #d1cfc5;*/
/*}*/

.failed {
    color: #ac354b;
}

.null {
    color: #333146;
}

button.bk-success {
    background-color: rgb(17, 64, 108) !important;
    border-color: rgb(17, 64, 108) !important;
}

button.bk-primary {
    background-color: rgb(17, 64, 108) !important;
    border-color: rgb(17, 64, 108) !important;
}

button.bk-button-text {
    background-color: transparent !important;
}

.bk-form-checkbox.is-checked .bk-checkbox {
    border-color: rgb(17, 64, 108) !important;
    background-color: rgb(17, 64, 108) !important;
    background-clip: border-box !important;
}

.bk-button-group .bk-button.is-selected {
    border-color: rgb(17, 64, 108) !important;
    color: rgb(17, 64, 108) !important;
}

.bk-button.bk-default:hover {
    border-color: rgb(17, 64, 108) !important;
    color: rgb(17, 64, 108) !important;
}

.bk-form-radio input[type=radio].is-checked {
    color: rgb(17, 64, 108) !important;
}

.bk-steps .bk-step.current .bk-step-icon, .bk-steps .bk-step.current .bk-step-number, .bk-steps .bk-step.current .bk-step-text {
    border-color: rgb(17, 64, 108) !important;
    background-color: rgb(17, 64, 108) !important;
}

.bk-steps .bk-step.done .bk-step-icon, .bk-steps .bk-step.done .bk-step-number, .bk-steps .bk-step.done .bk-step-text {
    border-color: rgb(17, 64, 108) !important;
    color: rgb(17, 64, 108) !important;
}

.bk-icon.icon-arrows-left-circle {
    color: rgb(17, 64, 108) !important;
}

.bk-icon.icon-arrows-right-circle {
    color: rgb(17, 64, 108) !important;
}

.bk-icon.icon-arrows-right-shape:hover {
    color: #df4d40 !important;
}

.bk-icon.icon-arrows-left-shape:hover {
    color: #df4d40 !important;
}

.bk-icon.icon-arrows-down-shape:hover {
    color: #df4d40 !important;
}

::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #f4f5f0;
}
.file-section:hover::-webkit-scrollbar {
    width: 6px;
}
.isSelected {
    background-color: #ecf3fe;
}

.edit-item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.can-copy {
    cursor: pointer;
}
.bk-has-border-tree li.leaf::after {
    border-style: none !important;
}
.bk-has-border-tree>li.tree-only-node::after{
    border-style: none !important;
}
.bk-has-border-tree>li.tree-only-node:after {
    border-style: none !important;
}
.bk-tag-selector .bk-tag-input {
    border-radius: 0.5rem !important;
    background: none !important;
}
</style>
