import store from '../../../vuex'
import api from "@/api";

export class AudioController {
    constructor() {
        this.audio = new Audio()
        this.handle = -1
        this.volume = 0 // 先设置默认值
        this.fadeDuration = 200
        this.buffer = new Audio()

        // 在store初始化后再获取volume
        setTimeout(() => {
            this.volume = store.state.common.volume
            console.log('volume',this.volume)
        }, 0)

        this.ontimeupdate = (value) => {
            store.commit('setCurrentTime', value)
        }
        this.ondurationchange = (value) => {
            store.commit('setDuration', value)
        }
        this.onpause = () => {
            store.commit('setPaused')
        }
        this.onstreamtitlechange = (value) => {
            store.commit('setStreamTitle', value)
        }
        this.onended = () => {
            if (store.getters['hasNext'] || store.state.common.repeat) {
                return store.dispatch('next')
            } else {
                return store.dispatch('resetQueue')
            }
        }
        this.onerror = () => { /* do nothing */ }

        // 初始化媒体会话控制
        if ('mediaSession' in navigator) {
            navigator.mediaSession.setActionHandler('play', () => {
                this.resume()
            })
            navigator.mediaSession.setActionHandler('pause', () => {
                this.pause()
            })
            navigator.mediaSession.setActionHandler('previoustrack', () => {
                store.dispatch('previous')
            })
            navigator.mediaSession.setActionHandler('nexttrack', () => {
                store.dispatch('next')
            })
        }
    }

    currentTime() {
        return this.audio.currentTime
    }

    duration() {
        return this.audio.duration
    }

    setBuffer(url) {
        this.buffer.src = url
    }

    setVolume(value) {
        this.cancelFade()
        this.volume = value
        this.audio.volume = value
    }

    async pause() {
        await this.fadeOut()
        this.audio.pause()
    }

    async resume() {
        this.audio.volume = 0.0
        await this.audio.play()
        this.fadeIn()
    }

    async seek(value) {
        await this.fadeOut(this.fadeDuration / 2.0)
        this.audio.volume = 0.0
        this.audio.currentTime = value
        await this.fadeIn(this.fadeDuration / 2.0)
    }

    async changeTrack(options) {
        if (this.audio) {
            this.cancelFade()
            endPlayback(this.audio, this.fadeDuration)
        }

        // 立即更新媒体会话元数据
        if ('mediaSession' in navigator) {
            // 确保所有必需字段都有有效值
            const title = options.title || options.name || '未知歌曲'
            const artist = options.artist || options.artistName || '未知艺术家'
            const album = options.album || options.albumName || '未知专辑'
            const artwork = options.artwork || options.image || options.coverArt

            try {
                const metadata = new MediaMetadata({
                    title,
                    artist,
                    album,
                    artwork: artwork ? [
                        { src: artwork, sizes: '512x512', type: 'image/jpeg' }
                    ] : [
                        // 提供一个默认封面
                        { src: '/assets/default-cover.jpg', sizes: '512x512', type: 'image/jpeg' }
                    ]
                })
                navigator.mediaSession.metadata = metadata
                navigator.mediaSession.playbackState = options.paused ? 'paused' : 'playing'
            } catch (error) {
                console.error('Media Session Update Error:', error)
            }
        }

        this.audio = new Audio(options.url)
        this.audio.onerror = () => {
            this.onerror(this.audio.error)
        }
        this.audio.onended = () => {
            this.onended()
        }
        this.audio.ontimeupdate = () => {
            this.ontimeupdate(this.audio.currentTime)
        }
        this.audio.ondurationchange = () => {
            this.ondurationchange(this.audio.duration)
        }
        this.audio.onpause = () => {
            this.onpause()
        }
        
        this.ondurationchange(this.audio.duration)
        this.ontimeupdate(this.audio.currentTime)
        this.onstreamtitlechange(null)
        this.audio.volume = 0.0

        if (options.paused !== true) {
            try {
                await this.audio.play()
                api.subsonic.scrobble(options.id)
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    console.warn(error)
                    return
                }
                throw error
            }
            this.fadeIn()
        }
    }

    cancelFade() {
        clearTimeout(this.handle)
    }

    fadeIn(duration = this.fadeDuration) {
        this.fadeFromTo(0.0, this.volume, duration).then()
    }

    fadeOut(duration = this.fadeDuration) {
        return this.fadeFromTo(this.volume, 0.0, duration)
    }

    fadeFromTo(from, to, duration) {
        const startTime = Date.now()
        const step = (to - from) / duration
        if (duration <= 0.0) {
            this.audio.volume = to
        }
        clearTimeout(this.handle)
        return new Promise((resolve) => {
            const run = () => {
                if (this.audio.volume === to) {
                    resolve()
                    return
                }
                const elapsed = Date.now() - startTime
                this.audio.volume = clamp(0.0, this.volume, from + (elapsed * step))
                this.handle = setTimeout(run, 10)
            }
            run()
        })
    }
}

function endPlayback(audio, duration) {
    async function fade(audio, from, to, duration) {
        if (duration <= 0.0) {
            audio.volume = to
            return audio
        }
        const startTime = Date.now()
        const step = (to - from) / duration
        while (audio.volume !== to) {
            const elapsed = Date.now() - startTime
            audio.volume = clamp(0.0, 1.0, from + (elapsed * step))
            await sleep(10)
        }
        return audio
    }
    audio.ontimeupdate = null
    audio.ondurationchange = null
    audio.onpause = null
    audio.onerror = null
    audio.onended = null
    audio.onloadedmetadata = null
    fade(audio, audio.volume, 0.0, duration)
        .catch((err) => console.warn('Error during fade out: ' + err.stack))
        .finally(() => {
            audio.pause()
        })
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

function clamp(min, max, value) {
    return Math.max(min, Math.min(value, max))
}
