<template>
    <aside class="w-full">
        <ul class="menu bg-base-100 rounded-box">
            <!--            <li :class="[$route.name == 'discover' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar">-->
            <!--                <router-link class="nav-link" :to="{path: '/discover'}">-->
            <!--                    <svg t="1729428061601" :class="['h-6', 'w-6', $route.name == 'discover' ? 'fill-neutral-content' : 'fill-current']" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6657" width="200" height="200"><path d="M512 300.8C396.8 300.8 307.2 396.8 307.2 512c0 57.6 25.6 115.2 70.4 153.6 12.8 12.8 32 12.8 44.8 0 12.8-12.8 12.8-32 0-44.8C384 595.2 364.8 556.8 364.8 512c0-83.2 64-147.2 147.2-147.2 83.2 0 147.2 64 147.2 147.2 0 83.2-64 147.2-147.2 147.2L505.6 659.2c-12.8 0-25.6 6.4-25.6 19.2-6.4 19.2-19.2 38.4-32 51.2-12.8 12.8-12.8 32 0 44.8 6.4 6.4 12.8 6.4 19.2 6.4 6.4 0 12.8 0 19.2-6.4 19.2-19.2 32-38.4 38.4-57.6 108.8-6.4 198.4-96 198.4-211.2C723.2 396.8 627.2 300.8 512 300.8zM121.6 512c0-115.2 38.4-224 115.2-307.2 12.8-12.8 12.8-32 0-44.8-12.8-12.8-32-12.8-44.8 0C108.8 262.4 64 384 64 512c0 128 44.8 249.6 134.4 345.6 6.4 6.4 12.8 12.8 25.6 12.8 6.4 0 12.8 0 19.2-6.4 12.8-12.8 12.8-32 0-44.8C166.4 736 121.6 627.2 121.6 512zM825.6 166.4c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8 76.8 83.2 115.2 198.4 115.2 307.2 0 115.2-38.4 224-115.2 307.2-12.8 12.8-12.8 32 0 44.8 6.4 6.4 12.8 6.4 19.2 6.4 6.4 0 19.2-6.4 25.6-12.8C915.2 761.6 960 640 960 512 960 384 915.2 262.4 825.6 166.4zM288 307.2C275.2 300.8 256 300.8 243.2 313.6c-38.4 57.6-64 128-64 198.4 0 70.4 19.2 140.8 64 198.4 6.4 6.4 12.8 12.8 25.6 12.8 6.4 0 12.8 0 19.2-6.4 12.8-12.8 19.2-25.6 6.4-44.8C262.4 627.2 243.2 569.6 243.2 512c0-57.6 19.2-115.2 51.2-160C300.8 339.2 300.8 320 288 307.2zM838.4 512c0-70.4-19.2-140.8-64-198.4-12.8-12.8-25.6-19.2-44.8-6.4-12.8 12.8-19.2 25.6-6.4 44.8 32 44.8 51.2 102.4 51.2 160 0 57.6-19.2 115.2-51.2 160-12.8 12.8-6.4 32 6.4 44.8 6.4 6.4 12.8 6.4 19.2 6.4 6.4 0 19.2-6.4 25.6-12.8C819.2 652.8 838.4 582.4 838.4 512z" p-id="6658"></path></svg>-->
            <!--                    <div :class="[$route.name == 'discover' ? 'text-neutral-content' : '', 'text-base']">发现音乐</div>-->
            <!--                </router-link>-->
            <!--            </li>-->
            <li :class="[$route.name == 'playing' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{ path: '/playing' }">
                    <svg t="1699584295731"
                        :class="['h-5', 'w-5', $route.name == 'playing' ? 'fill-neutral-content' : 'fill-current']"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1185" width="64"
                        height="64">
                        <path
                            d="M64 511.5C64 263.8 264.8 63 512.5 63S961 263.8 961 511.5V752c0 61.856-50.144 112-112 112h-56c-61.856 0-112-50.144-112-112V584c0-61.856 50.144-112 112-112h85.908C859.199 287.057 702.673 143 512.5 143S165.8 287.057 146.092 472H232c61.856 0 112 50.144 112 112v168c0 61.856-50.144 112-112 112h-56c-61.856 0-112-50.144-112-112V512v-0.25-0.25zM881 552h-88c-17.673 0-32 14.327-32 32v168c0 17.673 14.327 32 32 32h56c17.673 0 32-14.327 32-32V552z m-737 0v200c0 17.673 14.327 32 32 32h56c17.673 0 32-14.327 32-32V584c0-17.673-14.327-32-32-32h-88z"
                            p-id="1186"></path>
                    </svg>
                    <div :class="[$route.name == 'playing' ? 'text-neutral-content' : '', 'text-base', 'ml-1']">正在播放
                    </div>
                </router-link>
            </li>
            <li :class="[$route.name == 'playlists' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{ path: '/playlists/recently-added/' }">
                    <svg t="1699584409505"
                        :class="['h-5', 'w-5', $route.name == 'playlists' ? 'fill-neutral-content' : 'fill-current']"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2741" width="64"
                        height="64">
                        <path
                            d="M896 853.333333H597.333333v-85.333333h298.666667z m0-256H597.333333v85.333334h298.666667z m0-170.666666H597.333333v85.333333h298.666667z m-213.333333-42.666667a256 256 0 0 1-170.666667-65.493333V704a192 192 0 1 1-85.333333-159.573333V128h85.333333a170.666667 170.666667 0 0 0 170.666667 170.666667zM426.666667 704a106.666667 106.666667 0 1 0-106.666667 106.666667 106.666667 106.666667 0 0 0 106.666667-106.666667z"
                            p-id="2742"></path>
                    </svg>
                    <div :class="[$route.name == 'playlists' ? 'text-neutral-content' : '', 'text-base', 'ml-1']">播放列表
                    </div>
                </router-link>
            </li>
            <h2 class="menu-title text-base">LIBRARY</h2>
            <li :class="[$route.name == 'albums' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{ path: '/albums/recently-added/' }">
                    <svg :class="['inline-block', 'w-5', 'h-5', $route.name == 'albums' ? 'fill-neutral-content' : 'fill-current']"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4475" width="32"
                        height="32" fill="#4f14ee">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M511.701333 511.701333m-85.632 0a85.632 85.632 0 1 0 171.264 0 85.632 85.632 0 1 0-171.264 0Z">
                        </path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M512 85.333333C276.736 85.333333 85.333333 276.736 85.333333 512s191.402667 426.666667 426.666667 426.666667 426.666667-191.402667 426.666667-426.666667S747.264 85.333333 512 85.333333z m0 768c-188.202667 0-341.333333-153.130667-341.333333-341.333333s153.130667-341.333333 341.333333-341.333333 341.333333 153.130667 341.333333 341.333333-153.130667 341.333333-341.333333 341.333333z"
                            fill="" p-id="4477"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M512 256c-141.184 0-256 114.816-256 256h85.333333c0-94.122667 76.544-170.666667 170.666667-170.666667V256z"
                            fill="" p-id="4478"></path>
                    </svg>
                    <div :class="[$route.name == 'albums' ? 'text-neutral-content' : '', 'text-base']">专辑</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'artists' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{ path: '/artists/most-albums' }">
                    <svg t="1695911766499"
                        :class="['w-5', 'h-5', $route.name == 'artists' ? 'fill-neutral-content' : 'fill-current']"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11691">
                        <path
                            d="M535.253333 255.274667a170.666667 170.666667 0 0 0 234.154667 233.045333c-51.2-23.850667-104.618667-61.866667-138.24-95.488-34.048-34.048-72.149333-87.04-95.914667-137.557333z m65.706667-63.829334c12.928 40.277333 57.301333 107.818667 90.538667 141.056 32.682667 32.682667 99.072 76.074667 141.44 89.813334a170.666667 170.666667 0 0 0-231.936-230.869334z m-9.173333 389.290667a256.768 256.768 0 0 1-148.522667-148.522667l-259.84 373.504 34.858667 34.858667 373.504-259.84z m129.834666 13.653333L208.384 951.424l-135.808-135.808L429.653333 302.378667A256.042667 256.042667 0 0 1 938.666667 341.333333a256.042667 256.042667 0 0 1-217.045334 253.056z"
                            p-id="11692"></path>
                    </svg>
                    <div :class="[$route.name == 'artists' ? 'text-neutral-content' : '', 'text-base']">艺术家</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'tracks' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{ path: '/tracks/recently-added/' }">
                    <svg t="1711466361303"
                        :class="['h-5', 'w-5', $route.name == 'tracks' ? 'fill-neutral-content' : 'fill-current']"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2515" width="200"
                        height="200">
                        <path
                            d="M768 436.906667v53.76a21.333333 21.333333 0 0 1-21.333333 21.333333h-42.666667a21.333333 21.333333 0 0 1-21.333333-21.333333v-11.52a115.626667 115.626667 0 0 0-64-100.693334c-7.68-4.266667-14.506667-9.386667-21.333334-14.08v384A161.28 161.28 0 0 1 426.666667 896a161.28 161.28 0 0 1-170.666667-149.333333 161.28 161.28 0 0 1 170.666667-149.333334 183.04 183.04 0 0 1 85.333333 20.906667V149.333333a21.333333 21.333333 0 0 1 21.333333-21.333333h42.666667a21.333333 21.333333 0 0 1 21.333333 21.333333v7.253334a113.92 113.92 0 0 0 61.013334 103.253333A197.12 197.12 0 0 1 768 436.906667z"
                            p-id="2516"></path>
                    </svg>
                    <div :class="[$route.name == 'tracks' ? 'text-neutral-content' : '', 'text-base']">歌曲</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'genres' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{ path: '/genres/a-z/' }">
                    <svg t="1702346063187"
                        :class="['icon', 'w-4', 'h-4', $route.name == 'genres' ? 'fill-neutral-content' : 'fill-current']"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8844">
                        <path
                            d="M0 284.444444v455.111112a56.888889 56.888889 0 0 0 113.777778 0V284.444444a56.888889 56.888889 0 1 0-113.777778 0zM910.222222 284.444444v455.111112a56.888889 56.888889 0 0 0 113.777778 0V284.444444a56.888889 56.888889 0 0 0-113.777778 0zM455.111111 56.888889v910.222222a56.888889 56.888889 0 0 0 113.777778 0V56.888889a56.888889 56.888889 0 1 0-113.777778 0zM682.666667 170.666667v682.666666a56.888889 56.888889 0 0 0 113.777777 0V170.666667a56.888889 56.888889 0 0 0-113.777777 0zM227.555556 170.666667v682.666666a56.888889 56.888889 0 0 0 113.777777 0V170.666667a56.888889 56.888889 0 1 0-113.777777 0z">
                        </path>
                    </svg>
                    <div :class="[$route.name == 'genres' ? 'text-neutral-content' : '', 'text-base', 'ml-1']">风格</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'favourites' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{ path: '/favourites/albums/' }">
                    <svg xmlns="http://www.w3.org/2000/svg"
                        :class="['h-5', 'w-5', $route.name == 'favourites' ? 'fill-neutral-content' : '']" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    <div :class="[$route.name == 'favourites' ? 'text-neutral-content' : '', 'text-base']">收藏</div>
                </router-link>
            </li>
            <h2 class="menu-title text-base" v-if="isMiPlay">XIAOMI</h2>
            <li :class="[$route.name == 'xiaoMiQueue' ? 'bg-neutral' : '', 'rounded-xl']" @click="showSideBar" v-if="isMiPlay">
                <router-link class="nav-link" :to="{ path: '/xiaoMiQueue' }">
                    <svg t="1709777080693"
                        :class="['h-5', 'w-5', $route.name == 'xiaoMiQueue' ? 'fill-neutral-content' : 'fill-current']"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12709"
                        width="200" height="200">
                        <path
                            d="M768 0a128 128 0 0 1 128 128v768a128 128 0 0 1-128 128H256a128 128 0 0 1-128-128V128a128 128 0 0 1 128-128h512z m0 64H256a64 64 0 0 0-64 64v768a64 64 0 0 0 64 64h512a64 64 0 0 0 64-64V128a64 64 0 0 0-64-64z m-32 768v64h-448v-64h448zM512 256a256 256 0 1 1 0 512 256 256 0 0 1 0-512z m0 64a192 192 0 1 0 0 384 192 192 0 0 0 0-384z m0 128a64 64 0 1 1 0 128 64 64 0 0 1 0-128zM320 128v64H256V128h64z m448 0v64H704V128h64z">
                        </path>
                    </svg>
                    <div :class="[$route.name == 'xiaoMiQueue' ? 'text-neutral-content' : '', 'text-base', 'ml-1']">小爱播放
                    </div>
                </router-link>
            </li>
        </ul>
    </aside>
</template>
<script>

export default {
    components: {
    },
    methods: {
        showSideBar() {
            this.$parent.showSideBar()
        }
    },
    data() {
        return {
            isMiPlay: localStorage.getItem('mi_play_in_pl') === '1' || false
            // isMiPlay: false
        }
    }
}
</script>
