<template>
    <div class="relative w-full" ref="selectContainer">
        <div class="input input-bordered input-sm flex items-center">
            <input
                :type="type"
                v-model="localValue"
                class="grow"
                :placeholder="placeholder"
                @click="toggleDropdown"
                @keyup.enter="handleEnter"
                @change="handleChange"
            />
            <svg t="1727684299243" v-show="value" @click.prevent.stop="clearInput" class="fill-gray-300 h-4 w-4 cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1612" width="200" height="200"><path d="M512 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667S747.648 85.333333 512 85.333333zM671.061333 662.848c-19.242667 19.221333-50.410667 19.221333-69.632 0l-81.216-81.216-81.216 81.216c-19.242667 19.221333-50.410667 19.221333-69.632 0-19.221333-19.242667-19.221333-50.410667 0-69.632L450.581333 512l-81.237333-81.216c-19.221333-19.242667-19.221333-50.410667 0-69.632 19.242667-19.221333 50.410667-19.221333 69.632 0l81.216 81.216 81.216-81.216c19.242667-19.221333 50.410667-19.242667 69.632 0 19.221333 19.242667 19.221333 50.410667 0 69.632L589.824 512l81.237333 81.216C690.282667 612.458667 690.304 643.626667 671.061333 662.848z"></path></svg>
        </div>
        <div>
            <div v-if="localValue[localValue.length - 1] === '$' && showDropdown" class="mt-2">
                <div
                    :class="[
                        'absolute left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-lg w-full border border-solid max-h-[350px] overflow-y-auto',
                        dropdownDirection === 'up' ? 'bottom-full' : 'top-full'
                    ]">
                    <div
                        v-for="(item, index) in suggestions"
                        :key="'op'+index"
                        :class="['p-2', 'cursor-pointer', 'hover:bg-base-200', 'hover:rounded-lg']"
                        @click="selectSuggestion(item)"
                    >
                        <div class="flex justify-between">
                            <div>{{ item }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请输入'
        },
        type: {
            type: String,
            default: 'text'
        }
    },
    data() {
        return {
            showDropdown: true,
            suggestions: ['${title}', '${artist}','${first_artist}', '${album}', '${albumartist}', '${discnumber}', '${tracknumber}',
                '${filename}', '${file_song_name}', '${parent_path}', '${parent_parent_path}', '${lyrics}',
                '${comment}', '${year}', '${genre}', '${composer}', '${lyricist}', '${null}'],
            isSelecting: false,
            dropdownDirection: 'down',
            localValue: this.value
        };
    },
    computed: {
        optionsShow: {
            get() {
                return this.options
            },
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
        clearTimeout(this.blurTimeout);
    },
    watch: {
        value(newValue) {
            this.localValue = newValue; // 当prop更新时同步本地数据
        }
    },
    methods: {
        handleClickOutside(event) {
            const container = this.$refs.selectContainer;
            if (container && !container.contains(event.target)) {
                this.showDropdown = false;
            }
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
            if (this.showDropdown) {
                this.$nextTick(() => {
                    this.adjustDropdownPosition();
                })
            }
        },
        adjustDropdownPosition() {
            const inputElement = this.$el.querySelector('input');
            const viewportHeight = window.innerHeight;
            const { top, bottom, height } = inputElement.getBoundingClientRect();
            const spaceBelow = viewportHeight - bottom;
            const spaceAbove = top;
            if (spaceBelow < 400 && spaceAbove > 400) {
                this.dropdownDirection = 'up';
            } else {
                this.dropdownDirection = 'down';
            }
        },
        selectSuggestion(value) {
            this.localValue = this.localValue.substring(0, this.localValue.length - 1) + value
            this.$emit('input', this.localValue)
            this.showDropdown = false;
        },
        onBlur() {
            // 设置一个短暂的延迟
            this.blurTimeout = setTimeout(() => {
                if (this.isSelecting) {
                    this.isSelecting = false; // 重置标志
                    return; // 如果正在选择，则不关闭下拉菜单
                }
                this.showDropdown = false
            }, 150); // 150ms 是一个常用的值，可以根据实际情况调整

        },
        handleEnter() {
            this.$emit('enter', this.localValue);
        },
        clearInput() {
            this.showDropdown = false; // 关闭下拉菜单
            this.localValue = ''; // 清空输入
            this.$emit('input', ''); // 触发父组件的更新
        },
        handleChange() {
            this.$emit('input', this.localValue);
        }
    }
};
</script>

<style scoped>
.select-box {
    position: relative;
    display: inline-block;
}

.dropdown {
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.dropdown.active {
    display: block;
}

.dropdown div {
    padding: 5px;
}

.dropdown div:hover {
    background-color: #f8f8f8;
}

.menu.top-full {
    top: 100%;
}
.menu.bottom-full {
    bottom: 100%;
}
</style>
