<template>
    <div class="p-4">
        <div class="mb-3">
            <svg class="h-4 w-4 fill-current cursor-pointer" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg"
                 @click="backDir">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7.46961 13.5303L8.53027 12.4696L3.0606 6.99994L8.53027 1.53027L7.46961 0.469614L0.939283 6.99994L7.46961 13.5303Z"/>
            </svg>
        </div>
        <div class="flex items-center">
            <input type="text" placeholder="请输入文件夹路径："
                   v-model="filePath"
                   @enter="handleSearchFile"
                   class="input input-bordered input-sm w-full max-w-xs"/>
            <div @click="handleSearchFile(true)" class="cursor-pointer ml-2">
                <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.75006 12.6893L13.4697 7.96967L14.5304 9.03033L8.00006 15.5607L1.46973 9.03033L2.53039 7.96967L7.25006 12.6893L7.25006 0H8.75006L8.75006 12.6893Z"/>
                </svg>
            </div>
        </div>
        <div class="flex items-center mt-2">
            <single-select v-model="searchWord"
                           :options="filterOptions"
                           :placeholder="'请输入搜索关键词, 按Enter确认'"
                           @change="handleSearch"
                           @enter="handleSearch"
                           @null="handleSearch"
            >
            </single-select>
            <div class="dropdown dropdown-end">
                <label tabindex="0">
                    <div class="rounded-full cursor-pointer ml-2">
                        <svg class="h-4 w-4 fill-current" viewBox="0 0 16 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M0.969727 3.96967L4.93939 8.70228e-06H5.75006V12H4.25006V2.81066L2.03039 5.03033L0.969727 3.96967ZM11.7501 0H10.2501L10.2501 12H11.0607L15.0304 8.03033L13.9697 6.96967L11.7501 9.18934L11.7501 0Z"/>
                        </svg>
                    </div>
                </label>
                <ul tabindex="0"
                    class="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
                    <li><a href="javascript:;" @click="changeSorted('name')"
                           :class="{ 'isSelected': sortedField.includes('name') }">名称</a></li>
                    <li><a href="javascript:;" @click="changeSorted('update_time')"
                           :class="{ 'isSelected': sortedField.includes('update_time') }">修改时间</a></li>
                    <li><a href="javascript:;" @click="changeSorted('size')"
                           :class="{ 'isSelected': sortedField.includes('size') }">大小</a></li>
                    <li><a href="javascript:;" @click="changeSorted('state')"
                           :class="{ 'isSelected': sortedField.includes('state') }">状态</a></li>
                    <li><a href="javascript:;" @click="changeSorted('folder')"
                           :class="{ 'isSelected': sortedField.includes('folder') }">目录</a></li>
                </ul>
            </div>
        </div>
        <transition name="bk-slide-fade-down">
            <div style="margin-top: 10px;" v-show="fadeShowDir">
                <bk-tree
                    ref="tree1"
                    :data="treeListOne"
                    :multiple="true"
                    :node-key="'id'"
                    :has-border="true"
                    :tpl="tpl"
                    :draggable="true"
                    :drag-sort="true"
                    @on-click="nodeClickOne"
                    @on-check="nodeCheckTwo"
                    @on-expanded="nodeExpandedOne">
                </bk-tree>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import SingleSelect from "@/components/iview/SingleSelect";

export default {
    components: {SingleSelect},
    data() {
        return {
            treeListOne: [],
            fadeShowDir: false,
            sortedField: localStorage.getItem('sortedField') ? JSON.parse(localStorage.getItem('sortedField')) : [],
            checkedIdsList: [],
            checkedDataList: [],
            filterOptions: [
                {'id': 'state:null', 'name': '过滤: 未刮削'},
                {'id': 'state:success', 'name': '过滤: 成功'},
                {'id': 'state:failed', 'name': '过滤: 失败'}
            ]
        }
    },
    computed: {
        ...mapGetters(['geFullPath']),
        filePath: {
            get() {
                return this.$store.state.common.fullPath
            },
            set(value) {
                this.$store.commit('setFullPath', value)
                return value
            }
        },
        checkedIds: {
            get() {
                return this.$store.state.common.checkedIds
            },
            set(value) {
                this.$store.commit('setCheckedIds', value)
                return value
            }
        },
        checkedData: {
            get() {
                return this.$store.state.common.checkedData
            },
            set(value) {
                this.$store.commit('setCheckedData', value)
                return value
            }
        },
        searchWord: {
            get() {
                return this.$store.state.common.searchWord
            },
            set(value) {
                this.$store.commit('setSearchWord', value)
                return value
            }
        }
    },
    watch: {
        filePath: {
            handler() {
                this.handleSearchFile()
            }
        }
    },
    created() {
        const path = this.$route.query.path
        console.log(2333, path, this.$route.query.target)
        if (path) {
            this.filePath = path
            const target = this.$route.query.target || ''
            if (target === 'detail') {
                this.$router.replace({path: '/detail', query: {}})
            } else {
                this.$router.replace({path: '/', query: {}})
            }
        }
        this.handleSearchFile()
    },
    methods: {
        // 返回上一级
        backDir() {
            this.filePath = this.backPath(this.filePath)
        },
        backPath(path) {
            if (path === '/') {
                return '/app'
            } else if (path === '/app') {
                return '/app'
            } else if (path === '/app/') {
                return '/app'
            }
            // 使用正则表达式匹配最后一个斜杠及其后面的内容
            const regex = /\/([^\/]+)\/?$/
            const match = regex.exec(path)

            // 如果匹配到了最后一个斜杠及其后面的内容
            if (match) {
                // 截取掉最后一个斜杠及其后面的内容
                const parentPath = path.slice(0, match.index)
                // 返回回退后的路径
                return parentPath
            }

            // 如果没有匹配到最后一个斜杠及其后面的内容，则返回原始路径
            return path
        },
        // 文件目录
        handleSearchFile(refresh = false) {
            this.fadeShowDir = false
            this.checkedData = []
            this.checkedIds = []
            this.$parent.deleteFileID3List()
            this.$api.Task.fileList(
                {
                    'file_path': this.filePath,
                    sorted_fields: this.sortedField,
                    'search_word': this.searchWord,
                    'refresh': refresh
                }
            ).then((res) => {
                if (res.result) {
                    this.treeListOne = res.data.file_list_data
                    // if (this.searchWord) {
                    //     this.$nextTick(() => {
                    //         this.handleSearch()
                    //     })
                    // }
                    this.fadeShowDir = true
                    if (res.data.file_name !== '') {
                        let value = this.filePath.replace(res.data.file_name, '')
                        if (value.endsWith('/')) {
                            value = value.substring(0, value.length - 1)
                        }
                        this.$nextTick(() => {
                            this.filePath = value
                        })
                        this.$nextTick(() => {
                            this.nodeClickOne({'name': res.data.file_name, 'icon': 'icon-file'})
                        })
                    }
                } else {
                    if (res.message.status === 401) {
                        return
                    }
                    this.$myMsg.notify({
                        title: '查询失败！',
                        content: res.message || res.message.data.message,
                        type: 'error',
                        time: 5000,
                    })
                }
            })
            this.$parent.fetchFileID3FolderList('all')
        },
        // 过滤搜索
        handleSearch() {
            if (this.searchWord === '') {
                this.handleSearchFile()
                return
            }
            if (this.searchWord.startsWith('state:')) {
                // this.$refs.tree1.searchNode(this.filterNodeState)
                this.handleSearchFile()
            } else {
                this.$refs.tree1.searchNode(this.filterNode)
            }
            const searchResult = this.$refs.tree1.getSearchResult()
            this.isEmpty = searchResult.isEmpty
        },
        // 过滤函数
        filterNode(node) {
            return node['name'].toLowerCase().indexOf(this.searchWord.toLowerCase()) > -1
        },
        // 过滤函数
        filterNodeState(node) {
            const searchWord = this.searchWord.split(':')[1]
            if (searchWord === 'null') {
                return node['state'].toLowerCase().indexOf('null'.toLowerCase()) > -1 || node['state'].toLowerCase().indexOf('wait'.toLowerCase()) > -1
            } else {
                return node['state'].toLowerCase().indexOf(searchWord.toLowerCase()) > -1
            }
        },
        // 更改排序条件
        changeSorted(element) {
            if (this.sortedField.includes(element)) {
                this.sortedField.splice(this.sortedField.indexOf(element), 1)
            } else {
                this.sortedField.push(element)
            }
            this.$store.commit('setSortedField', this.sortedField)
            this.handleSearchFile()
        },
        // 点击树的节点
        nodeClickOne(node) {
            if (node.icon === 'icon-folder') {
                if (this.filePath.endsWith('/')) {
                    this.filePath = this.filePath + node.name
                } else {
                    this.filePath = this.filePath + '/' + node.name
                }
            } else {
                if (node.children && node.children.length > 0) {
                    return
                }
                this.isShowEdit = true
                this.showId3List = this.checkedIds.length > 0
                this.musicInfo = this.baseMusicInfo
                this.fileName = node.name
                if (this.filePath.endsWith('/')) {
                    this.fullPath = this.filePath + node.name
                } else {
                    this.fullPath = this.filePath + '/' + node.name
                }
                this.$parent.showEditPageDrawer(this.filePath, node.name)
            }
        },
        // 勾选树的节点
        nodeCheckTwo(node, checked) {
            this.nowSortedKey = ''
            if (checked) {
                this.fadeShowDetail = false
                this.isShowEdit = false
                // 清空表单
                this.musicInfo = this.baseMusicInfo
                this.musicInfoManual = this.baseMusicInfo
                this.albumArtistInputList = []
                this.languageInputList = []
                this.genreInputList = []
                // 全选时
                if (node.children && node.children.length > 0) {
                    this.checkedDataList = []
                    this.checkedIdsList = []
                    node.children.forEach(el => {
                        this.checkedDataList.push({
                                checked: el.checked,
                                icon: el.icon,
                                id: el.id,
                                name: el.name,
                                title: el.title
                            })
                        this.checkedIdsList.push(el.id)
                    })
                    this.checkedIds = this.checkedIdsList
                    this.checkedData = this.checkedDataList
                } else {
                    this.checkedData.push({
                        checked: node.checked,
                        icon: node.icon,
                        id: node.id,
                        name: node.name,
                        title: node.title
                    })
                    this.checkedIds.push(node.id)
                }
            } else {
                this.isShowEdit = false
                // 全部取消
                if (node.children && node.children.length > 0) {
                    this.checkedData = []
                    this.checkedIds = []
                } else {
                    const index = this.checkedIds.indexOf(node.id)
                    if (index !== -1) {
                        const id = this.checkedData[index].id
                        this.checkedData.splice(index, 1)
                        this.checkedIds.splice(index, 1)
                    }
                }
            }
        },
        nodeExpandedOne(node, expanded) {
            console.log('nodeExpandedOne', node, expanded)
        },
        // 节点模板
        tpl(node, ctx) {
            // 如果在某些情况下 h 不能自动注入而报错，需将 h 参数写上；一般来说 h 默认是第一参数，但是现在改为第一参数会导致已经使用的用户都需要修改，所以先放在最后。
            // 如果 h 能自动注入则可以忽略 h 参数，无需写上，否则 h 参数会重复。
            let color
            if (node.state === 'success') {
                color = 'text-slate-500'
            } else if (node.state === 'failed') {
                color = 'text-red-500'
            } else {
                color = 'text-current'
            }
            const titleClass = node.selected ? 'node-title node-selected' : 'node-title ' + color + ' hover:underline'
            if (!node.children) {
                node.children = []
            }
            if (node.title.length > 20) {
                if (node.parent === null) {
                    return <span>
                    <span class={titleClass} domPropsInnerHTML={node.title.slice(0, 20)}
                          onClick={() => {
                              this.nodeClickOne(node)
                          }} v-bk-tooltips={node.title}>
                    </span>
                            <span class="btn btn-xs ml-1" onClick={() => this.markFolder(node.name)}
                                  v-bk-tooltips="将文件夹标记为已完成" domPropsInnerHTML={node.children.length}>
                            </span>
                    </span>
                } else {
                    return <span>
                    <span class={titleClass} domPropsInnerHTML={node.title.slice(0, 20)}
                          onClick={() => {
                              this.nodeClickOne(node)
                          }} v-bk-tooltips={node.title}>
                    </span>
                    </span>
                }
            } else {
                if (!node.parent) {
                    return <span>
                    <span class={titleClass} domPropsInnerHTML={node.title.slice(0, 20)}
                          onClick={() => {
                              this.nodeClickOne(node)
                          }}>
                    </span>
                            <span class="btn btn-xs ml-1" onClick={() => this.markFolder(node.name)}
                                  v-bk-tooltips="将文件夹标记为已完成" domPropsInnerHTML={node.children.length}>
                            </span>
                    </span>
                } else {
                    return <span>
                    <span class={titleClass} domPropsInnerHTML={node.title.slice(0, 20)}
                          onClick={() => {
                              this.nodeClickOne(node)
                          }}>
                    </span>
                    </span>
                }
            }
        },
        // 标记文件夹为已处理
        markFolder() {
            this.$api.Task.markFolderState({'full_path': this.filePath}).then((res) => {
                if (res.result) {
                    this.$myMsg.notify({
                        title: '标记成功！',
                        content: '',
                        type: 'success',
                        time: 2000,
                    })
                    this.handleSearchFile()
                } else {
                    this.$myMsg.notify({
                        title: '创建失败！',
                        content: res.messagee ? res.message : '创建失败',
                        type: 'error',
                        time: 5000,
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
/deep/ .bk-tree .bk-form-checkbox--checked {
    border-color: oklch(var(--n) / 70%) !important;
    background-color: oklch(var(--n) / 70%) !important;
}

/deep/ .bk-tree .bk-form-checkbox--indeterminate {
    border-color: oklch(var(--nc)) !important;
    background-color: oklch(var(--nc)) !important;
}

/deep/ .bk-has-border-tree > li.tree-first-node:before {
    display: none;
}

/deep/ .bk-form-checkbox {
    border-radius: 4px;
}
</style>
