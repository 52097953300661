<template>
    <div class="overflow-y-auto h-screen-header" ref="favourateview">
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <a v-for="{ value, text } in options" :key="value"
                :class="{ 'tab': true, 'tab-active': sortValue === value }">
                <router-link :to="{ 'path': '/favourites/' + value }" class="w-full">
                    {{ text }}
                </router-link>
            </a>
        </div>
        <ArtistList v-if="sortValue === 'artists'" :items="details.artists" />
        <TrackList v-else-if="sortValue === 'tracks'" :tracks="details.tracks" />
        <AlbumList v-else :items="details.albums" />
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
        <button
            v-if="curScrollTop > 700"
            @click="scrollToTop"
            class="fixed bottom-8 right-8 bg-neutral-content hover:bg-accent hover: text-accent-content text-neutral font-bold py-2 px-4 rounded-full shadow-xl transition-colors duration-300"
        >
            <svg t="1730534469934" class="icon h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2399" width="200" height="200"><path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3c-3.8 5.3-0.1 12.7 6.5 12.7h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z" p-id="2400"></path></svg>
        </button>
    </div>
</template>
<script>
    import AlbumList from '@/views/library/album/AlbumList.vue'
    import ArtistList from '@/views/library/artist/ArtistList.vue'
    import TrackList from '@/views/library/track/TrackList.vue'

    export default {
        name: 'favourites',
        components: {
            AlbumList,
            ArtistList,
            TrackList
        },
        props: {
        },
        data() {
            return {
                options: [
                    {text: '专辑', value: 'albums'},
                    {text: '艺术家', value: 'artists'},
                    {text: '歌曲', value: 'tracks'}
                ],
                loading: false,
                details: {'albums': [], 'artists': [], 'tracks': []},
                curScrollTop: 0,
                offset: 0,
                hasMore: true
            }
        },
        computed: {
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        mounted() {
            this.$refs.favourateview.addEventListener('scroll', this.listenBottomOut)
            window.favourateview = this.$refs.favourateview
        },
        async created() {
            this.loadMore()
        },
        methods: {
            loadMore() {
                this.loading = true
                return this.$api.subsonic.getFavourites(this.sortValue,30, this.offset).then(details => {
                    const items = details[this.sortValue]
                    this.details[this.sortValue].push(...items)
                    this.offset += items.length
                    this.hasMore = items.length > 0
                    this.loading = false
                }).catch((err)=> {
                })
            },
            listenBottomOut() {
                if (this.hasMore && !this.loading) {
                    const scrollTop = window.favourateview.scrollTop || document.body.scrollTop
                    const scrollHeight = window.favourateview.scrollHeight || document.body.scrollHeight
                    const clientHeight = window.favourateview.clientHeight || document.body.clientHeight
                    this.curScrollTop = scrollTop
                    if (scrollTop + clientHeight >= scrollHeight - 50) {
                        this.loadMore()
                    }
                }
            },
            scrollToTop() {
                window.favourateview.scrollTop = 0
            }
        }
    }
</script>
