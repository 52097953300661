import {GET, POST, PUT, DELETE, reUrl} from '../../axiosconfig/axiosconfig'

export default {
    login: function (params) {
        return POST(reUrl + '/apimt/token/', params)
    },
    loginInfo: function (params) {
        return GET(reUrl + '/user/info/', params)
    },
    activeCode: function (params) {
        return POST(reUrl + '/user/active/', params)
    },
    logout: function (params) {
        return POST(reUrl + '/logout/', params)
    },
    fileList: function (params) {
        return POST(reUrl + '/apimt/file_list/', params)
    },
    fileID3List: function (params) {
        return POST(reUrl + '/apimt/file_id3_list/', params)
    },
    musicId3: function (params) {
        return POST(reUrl + '/apimt/music_id3/', params)
    },
    updateId3: function (params) {
        return POST(reUrl + '/apimt/update_id3/', params)
    },
    batchUpdateId3: function (params) {
        return POST(reUrl + '/apimt/batch_update_id3/', params)
    },
    importMusicIn: function (params) {
        return POST(reUrl + '/apimt/import_music/', params)
    },
    batchAutoUpdateId3: function (params) {
        return POST(reUrl + '/apimt/batch_auto_update_id3/', params)
    },
    tidyFolder: function (params) {
        return POST(reUrl + '/apimt/tidy_folder/', params)
    },
    removeNullFolder: function (params) {
        return POST(reUrl + '/apimt/remove_null_folder/', params)
    },
    deleteFiles: function (params) {
        return POST(reUrl + '/apimt/delete_files/', params)
    },
    deleteTrack: function (params) {
        return POST(reUrl + '/apimt/delete_track/', params)
    },
    fetchId3Title: function (params) {
        return POST(reUrl + '/apimt/fetch_id3_by_title/', params)
    },
    fetchArtists: function (params) {
        return POST(reUrl + '/apimt/fetch_artist_by_keyword/', params)
    },
    postArtist: function (params) {
        const id = params.id
        return PUT(reUrl + `/music/artist/${id}/`, params)
    },
    deleteArtist: function (params) {
        const id = params.id
        return DELETE(reUrl + `/music/artist/${id}/`, params)
    },
    postAlbum: function (params) {
        const id = params.id
        return PUT(reUrl + `/music/album/${id}/`, params)
    },
    deleteAlbum: function (params) {
        const id = params.id
        return DELETE(reUrl + `/music/album/${id}/`, params)
    },
    deleteGenre: function (params) {
        const id = params.id
        return DELETE(reUrl + `/music/genre/${id}/`, params)
    },
    postGenre: function (params) {
        const id = params.id
        return PUT(reUrl + `/music/genre/${id}/`, params)
    },
    genrePlaylistCover: function (params) {
        const id = params.id
        return POST(reUrl + `/music/playlist/${id}/contact_image/`, params)
    },
    putPlayList: function (params) {
        const id = params.id
        return PUT(reUrl + `/music/playlist/${id}/`, params)
    },
    fetchLyric: function (params) {
        return POST(reUrl + '/apimt/fetch_lyric/', params)
    },
    extractLyric: function (params) {
        return POST(reUrl + '/apimt/extract_lyric/', params)
    },
    translationLyc: function (params) {
        return POST(reUrl + '/apimt/translation_lyc/', params)
    },
    simplifiedFields: function (params) {
        return POST(reUrl + '/apimt/simplified_fields/', params)
    },
    splitFilename: function (params) {
        return POST(reUrl + '/apimt/split_filename/', params)
    },
    replaceText: function (params) {
        return POST(reUrl + '/apimt/replace_text/', params)
    },
    fixEncoding: function (params) {
        return POST(reUrl + '/apimt/fix_encoding/', params)
    },
    checkDuplicate: function (params) {
        return POST(reUrl + '/apimt/check_file_duplicates/', params)
    },
    formatMusic: function (params) {
        return POST(reUrl + '/apimt/format_music_type/', params)
    },
    splitCueMusic: function (params) {
        return POST(reUrl + '/apimt/split_cue/', params)
    },
    getRecord: function (params) {
        return GET(reUrl + '/apimt/record/', params)
    },
    postRecord: function (params) {
        return POST(reUrl + '/apimt/record/', params)
    },
    getSys: function (params) {
        return GET(reUrl + '/apimt/sys/', params)
    },
    getSysDict: function (params) {
        return GET(reUrl + '/apimt/sys/dict/', params)
    },
    getSysInfo: function (params) {
        return GET(reUrl + '/apimt/sys/info/', params)
    },
    getSysExist: function (params) {
        return GET(reUrl + '/apimt/sys_exist/', params)
    },
    UpdateSys: function (params) {
        return POST(reUrl + '/apimt/sys/', params)
    },
    miServiceTest: function (params) {
        return GET(reUrl + '/apimt/mi_service/test/', params)
    },
    miServiceSearch: function (params) {
        return GET(reUrl + '/apimt/mi_service/search_device/', params)
    },
    miServicePlay: function (params) {
        return GET(reUrl + '/apimt/mi_service/play/', params)
    },
    miServicePlayList: function (params) {
        return POST(reUrl + '/apimt/mi_service/play_list/', params)
    },
    miServicePlaying: function (params) {
        return GET(reUrl + '/apimt/mi_service/playing/', params)
    },
    miServicePlayQueue: function (params) {
        return GET(reUrl + '/apimt/mi_service/play_queue/', params)
    },
    miServiceStopQueue: function (params) {
        return GET(reUrl + '/apimt/mi_service/stop_queue/', params)
    },
    miServiceUpdatePlaylistOrder: function (params) {
        return POST(reUrl + '/apimt/mi_service/update_playlist_order/', params)
    },
    miServiceShuffle: function (params) {
        return GET(reUrl + '/apimt/mi_service/shuffle/', params)
    },
    miServiceRandom: function (params) {
        return GET(reUrl + '/apimt/mi_service/random/', params)
    },
    miServicePause: function (params) {
        return GET(reUrl + '/apimt/mi_service/pause/', params)
    },
    supervisorControl: function (params) {
        return POST(reUrl + '/apimt/supervisor/control/', params)
    },
    getMusicCount: function (params) {
        return GET(reUrl + '/music/music_collection/', params)
    },
    getProgressRate: function (params) {
        return POST(reUrl + '/music/music_collection/progress/', params)
    },
    getProgressStatic: function (params) {
        return GET(reUrl + '/music/music_collection/static/', params)
    },
    getListeningRecord: function (params) {
        return GET(reUrl + '/music/listening/record/', params)
    },
    getHotGenres: function (params) {
        return GET(reUrl + '/music/record/hot_genres/', params)
    },
    getHotDecades: function (params) {
        return GET(reUrl + '/music/record/hot_decades/', params)
    },
    getPlatformStats: function (params) {
        return GET(reUrl + '/music/record/platform_stats/', params)
    },
    getListeningStats: function (params) {
        return GET(reUrl + '/music/record/listening_stats/', params)
    },
    getListeningClock: function (params) {
        return GET(reUrl + '/music/record/listening_clock/', params)
    },
    getAuditLog: function (params) {
        return GET(reUrl + '/apimt/audit_log/', params)
    },
    getAuditLogDetail: function (params) {
        const id = params.id

        return GET(reUrl + `/apimt/audit_log/${id}/detail_log/`, params)
    },
    stopAuditLogDetail: function (params) {
        const id = params.id

        return POST(reUrl + `/apimt/audit_log/${id}/stop/`, params)
    },
    stopAuditLogDetailRun: function (params) {
        const id = params.id

        return POST(reUrl + `/apimt/audit_log/${id}/stop_detail/`, params)
    },
    changeStatusAuditLogDetailRun: function (params) {
        const id = params.id

        return POST(reUrl + `/apimt/audit_log/${id}/change_status/`, params)
    },
    getRuntimeLog: function (params) {
        return GET(reUrl + '/apimt/runtime_log/', params)
    },
    getRuntimeMiLog: function (params) {
        return GET(reUrl + '/apimt/runtime_log/mi_log/', params)
    },
    getResourceScript: function (params) {
        return GET(reUrl + '/script/tag_resource/', params)
    },
    getActionScript: function (params) {
        return GET(reUrl + '/script/action_script/', params)
    },
    debugTask1: function (params) {
        return GET(reUrl + '/apimt/task1/', params)
    },
    debugTask2: function (params) {
        return GET(reUrl + '/apimt/task2/', params)
    },
    batchAutoUpdateArtist: function (params) {
        return POST(reUrl + '/apimt/batch_auto_update_artist/', params)
    },
    markFolderState: function (params) {
        return POST(reUrl + '/apimt/update_folder_state/', params)
    },
    WeekRecord: function (params) {
        return POST(reUrl + '/music/record/week_record/', params)
    },
    getPerfectMusic: function (params) {
        return POST(reUrl + '/music/record/perfect_music/', params)
    },
    importPlaylist: function (params) {
        return POST(reUrl + '/music/playlist/import_playlist/', params)
    },
    exportConfig: function (params) {
        return POST(reUrl + '/apimt/sys/export_config/', params)
    },
    importConfig: function (params) {
        return POST(reUrl + '/apimt/sys/import_config/', params)
    },
    uploadLog: function (params) {
        return POST(reUrl + '/apimt/sys/upload_log/', params)
    },
    openApi: function (params) {
        return GET(reUrl + '/apimt/open_api/', params)
    },
}
