<template>
    <div class="rounded-xl overflow-x-auto">
        <table class="table" ref="trackTable">
            <!-- head -->
            <thead>
                <tr>
                    <th>#</th>
                    <th>标题</th>
                    <th class="!hidden md:!table-cell" v-if="!noAlbum">专辑</th>
                    <th class="!hidden md:!table-cell">时长</th>
                    <th class="!hidden md:!table-cell">大小</th>
                    <th class="!hidden md:!table-cell">比特率</th>
                    <th class="!hidden md:!table-cell">播放</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in tracks" :key="index" :draggable="true"
                    @dragstart="dragStart($event, index)"
                    @dragover.prevent
                    @dragenter.prevent
                    @drop="drop($event, index)"
                    @dragend="dragEnd($event)"
                    :class="['hover:bg-base-300', item.id === trackId && isPlaying ? 'bg-base-300 text-purple-600' : '']">
                    <td @click="play(index)" class="cursor-move">
                        <div v-if="item.id === trackId && isPlaying">
                            <span class="loading loading-bars loading-xs"></span>
                        </div>
                        <div v-else>
                            <div v-if="indexField === 'index'">
                                {{ index + 1 }}
                            </div>
                            <div v-else>
                                {{ item.track }}
                            </div>
                        </div>
                    </td>
                    <td @click="play(index)" class="max-w-[250px] min-w-[200px]">
                        <div class="flex items-center gap-3">
                            <div class="avatar">
                                <div class="mask mask-squircle w-12 h-12 tile-img">
                                    <img :src="item.image" v-if="item.image"
                                         alt="" />
                                    <img v-else src="/static/dist/img/album_null.jpg">
                                </div>
                            </div>
                            <div>
                                <div class="font-bold truncate w-[180px]">
                                    {{item.title}}
                                    <div class="badge ml-1" v-if="item.fromFolder === 'webdav'">
                                        <svg t="1705398013860" class="icon fill-current w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2454" width="200" height="200"><path d="M533.333333 256a212.416 212.416 0 0 1 212.864 205.333333l0.277334 7.488a52.970667 52.970667 0 0 0 35.413333 48.064l3.136 1.088a131.584 131.584 0 0 1 89.024 109.418667A128.128 128.128 0 0 1 746.666667 768H303.552a152.554667 152.554667 0 0 1-153.301333-132.629333 148.821333 148.821333 0 0 1 123.306666-163.690667l7.296-1.237333a53.034667 53.034667 0 0 0 42.88-40.682667l1.6-7.210667A214.570667 214.570667 0 0 1 533.333333 256m0-64a277.333333 277.333333 0 0 0-270.442666 216.554667A213.226667 213.226667 0 0 0 298.666667 832h448a192 192 0 0 0 63.488-373.034667A277.12 277.12 0 0 0 533.333333 192z" p-id="2455"></path><path d="M405.333333 618.666667m32 0l192 0q32 0 32 32l0 0q0 32-32 32l-192 0q-32 0-32-32l0 0q0-32 32-32Z" fill="#FF9C00" p-id="2456"></path></svg>
                                    </div>
                                </div>
                                <div class="text-sm opacity-50 truncate">{{item.artist}}</div>
                            </div>
                        </div>
                    </td>
                    <td @click="play(index)" class="!hidden md:!table-cell" v-if="!noAlbum">{{ item.album }}</td>
                    <td @click="play(index)" class="!hidden md:!table-cell">{{ formatDuration(item.duration) }}</td>
                    <td @click="play(index)" class="!hidden md:!table-cell">{{ formatSize(item.size) }}</td>
                    <td @click="play(index)" class="!hidden md:!table-cell">{{ item.bitrate }}kps</td>
                    <td @click="play(index)" class="!hidden md:!table-cell">{{ item.playsCount }}</td>
                    <td class="!hidden md:!table-cell">
                        <track-action :item="item" :index="index"></track-action>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import TrackAction from "@/views/library/track/TrackAction";
    import {mapGetters} from 'vuex'
    export default {
        components: {TrackAction},
        props: {
            tracks: {type: Array, required: true},
            noAlbum: {type: Boolean, default: false},
            noArtist: {type: Boolean, default: false},
            noDuration: {type: Boolean, default: false},
            indexField: {type: String, default: 'tracknumber'}
        },
        data() {
            return {
                playLists: [],
                id: this.$route.params.id,
                isShowCreatePlist: false,
                selectPlaylist: '',
                selectTrackID: '',
                plName: '',
                draggedIndex: null,
            }
        },
        computed: {
            ...mapGetters(['trackId']),
            isPlaying() {
                return this.$store.state.common.isPlaying
            }
        },
        created() {
        },
        methods: {
            formatDuration(value) {
                if (!isFinite(value)) {
                    return '∞'
                }
                const minutes = Math.floor(value / 60)
                const seconds = Math.floor(value % 60)
                return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
            },
            formatSize(value) {
                return (value / 1024 / 1024).toFixed(1) + ' MB'
            },
            play(index) {
                if (this.tracks[index].id === this.trackId) {
                    return this.$store.dispatch('playPause')
                }
                return this.$store.dispatch('playTrackList', {
                    index,
                    tracks: this.tracks
                })
            },
            dragstart(item, event) {
                if (!item.isStream) {
                    event.dataTransfer.setData('application/x-track-id', item.id)
                }
            },
            addFavouriteTrack(item) {
                if (item.favourite) {
                    this.$api.subsonic.removeFavourite(item.id, 'track').then((res) => {
                        item.favourite = false
                    })
                } else {
                    this.$api.subsonic.addFavourite(item.id, 'track').then((res) => {
                        item.favourite = true
                    })
                }
            },
            removePlayListFunc(index) {
                this.$api.subsonic.removeFromPlaylist(this.id, index).then((res) => {
                    this.$myMsg.notify({
                        title: '删除成功！',
                        type: 'success',
                        time: 2000,
                    })
                    this.tracks.splice(index, 1)
                })
            },
            redirctEditDetail(path) {
                if (this.$route.name !== 'home') {
                    this.$router.push({name: 'home'})
                }
                this.$store.commit('setFullPath', path)
            },
            dragStart(event, index) {
                this.draggedIndex = index;
                event.dataTransfer.effectAllowed = 'move';
                // 设置拖动时的透明度
                event.target.style.opacity = '0.4';
            },
            dragEnd(event) {
                // 恢复所有元素的透明度
                const rows = event.target.parentElement.getElementsByTagName('tr');
                for (let row of rows) {
                    row.style.opacity = '1';
                }
            },
            drop(event, index) {
                event.preventDefault();
                // 获取拖动的行和目标行
                const draggedItem = this.tracks[this.draggedIndex];
                
                // 直接修改 tracks 数组
                this.tracks.splice(this.draggedIndex, 1);
                this.tracks.splice(index, 0, draggedItem);
                
                // 如果是播放列表，需要更新后端
                if (this.$route.name === 'playlist') {
                    this.updatePlaylistOrder(this.tracks);
                }
                
                // 重置拖动状态
                this.draggedIndex = null;
            },
            async updatePlaylistOrder(newTracks) {
                try {
                    // 获取播放列表 ID
                    const playlistId = this.$route.params.id;
                    
                    // 构建新的顺序数据
                    const trackIds = newTracks.map(track => track.id);
                    
                    // 调用后端 API 更新顺序
                    await this.$api.subsonic.updatePlaylistOrder(playlistId, trackIds);
                    
                    this.$myMsg.notify({
                        title: '更新成功！',
                        type: 'success',
                        time: 2000,
                    });
                } catch (error) {
                    console.error('Failed to update playlist order:', error);
                    this.$myMsg.notify({
                        title: '更新失败！',
                        type: 'error',
                        time: 2000,
                    });
                }
            }
        }
    }
</script>

<style scoped>
.cursor-move {
    cursor: move;
}

tr.dragging {
    opacity: 0.4;
}
</style>
