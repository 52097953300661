<template>
    <div class="relative form-control w-full max-w-xs" ref="selectContainer">
        <label class="input input-bordered input-sm flex items-center gap-2">
            <input
                type="text"
                v-model="selectedOption"
                class="grow"
                :placeholder="placeholder"
                @click="toggleDropdown"
                @keyup.enter="handleEnter"
            />
            <svg t="1727684299243" v-show="selectedOption" @click.prevent.stop="clearInput" class="fill-gray-300 h-4 w-4 cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1612" width="200" height="200"><path d="M512 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667S747.648 85.333333 512 85.333333zM671.061333 662.848c-19.242667 19.221333-50.410667 19.221333-69.632 0l-81.216-81.216-81.216 81.216c-19.242667 19.221333-50.410667 19.221333-69.632 0-19.221333-19.242667-19.221333-50.410667 0-69.632L450.581333 512l-81.237333-81.216c-19.221333-19.242667-19.221333-50.410667 0-69.632 19.242667-19.221333 50.410667-19.221333 69.632 0l81.216 81.216 81.216-81.216c19.242667-19.221333 50.410667-19.242667 69.632 0 19.221333 19.242667 19.221333 50.410667 0 69.632L589.824 512l81.237333 81.216C690.282667 612.458667 690.304 643.626667 671.061333 662.848z"></path></svg>
        </label>
        <div class="mt-2">
            <div v-if="selectedOption[selectedOption.length - 1] === '$' && isVar">
                <div
                     :class="[
                        'absolute left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-lg w-full border border-solid max-h-[350px] overflow-y-auto',
                        dropdownDirection === 'up' ? 'bottom-full' : 'top-full'
                    ]">
                    <div
                        v-for="(item, index) in suggestions"
                        :key="'op'+index"
                        :class="['p-2', 'cursor-pointer', 'hover:bg-base-200', 'hover:rounded-lg']"
                        @click="selectSuggestion(item)"
                    >
                        <div class="flex justify-between">
                            <div>{{ item }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="showDropdown && optionsShow.length >0"
                     :class="[
                        'absolute left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-lg w-full border border-solid',
                        dropdownDirection === 'up' ? 'bottom-full' : 'top-full'
                    ]">
                    <div
                        v-for="(item, index) in optionsShow"
                        :key="'op'+index"
                        :class="['menu-item', item.id === value ? 'bg-base-200' : '', 'p-2', 'cursor-pointer', 'hover:bg-base-200', 'hover:rounded-lg']"
                        @click="selectOption(item.id)"
                    >
                        <div class="flex justify-between">
                            <div>{{ item.name }}</div>
                            <div v-if="value === item.id" class="fill-current">✓</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
            default: ''
        },
        options: {
            type: Array,
            required: true,
            default: []
        },
        placeholder: {
            type: String,
            default: '请输入'
        },
        isVar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDropdown: false,
            suggestions: ['${title}', '${artist}', '${first_artist}','${album}', '${albumartist}', '${discnumber}', '${tracknumber}',
            '${filename}', '${file_song_name}', '${parent_path}', '${parent_parent_path}', '${lyrics}',
            '${comment}', '${year}', '${genre}', '${composer}', '${lyricist}', '${null}'],
            isSelecting: false,
            dropdownDirection: 'down'
        };
    },
    computed: {
        selectedOption: {
            get() {
                return this.options.find(opt => opt.id === this.value) ? this.options.find(opt => opt.id === this.value).name : this.value || ''
            },
            set(v) {
                this.$emit('input', v);
                if(v === ''){
                    this.$emit('null', '')
                    this.showDropdown = false
                }
            }
        },
        optionsShow: {
            get() {
                // if (this.value && !this.value.startsWith('state:')) {
                //     return this.options.filter(opt => opt.name.includes(this.value))
                // } else {
                //     return this.options
                // }
                return this.options
            },
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
        clearTimeout(this.blurTimeout);
    },
    methods: {
        handleClickOutside(event) {
            const container = this.$refs.selectContainer;
            if (container && !container.contains(event.target)) {
                this.showDropdown = false;
            }
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
            if (this.showDropdown) {
                this.$nextTick(() => {
                    this.adjustDropdownPosition();
                })
            }
        },
        adjustDropdownPosition() {
            const inputElement = this.$el.querySelector('input');
            const dropdownElement = this.$el.querySelector('.menu');
            const viewportHeight = window.innerHeight;
            const { top, bottom, height } = inputElement.getBoundingClientRect();
            const spaceBelow = viewportHeight - bottom;
            const spaceAbove = top;

            if (spaceBelow < dropdownElement.offsetHeight && spaceAbove > dropdownElement.offsetHeight) {
                this.dropdownDirection = 'up';
            } else {
                this.dropdownDirection = 'down';
            }
        },
        selectOption(option) {
            this.selectedOption = option;
            this.$emit('change', option);
            this.isSelecting = true;
            this.showDropdown = false;
        },
        selectSuggestion(value) {
            this.selectedOption = this.selectedOption.substring(0, this.selectedOption.length - 1) + value
            this.showDropdown = false;
        },
        onBlur() {
            // 设置一个短暂的延迟
            this.blurTimeout = setTimeout(() => {
                if (this.isSelecting) {
                    this.isSelecting = false; // 重置标志
                    return; // 如果正在选择，则不关闭下拉菜单
                }
                this.showDropdown = false
            }, 150); // 150ms 是一个常用的值，可以根据实际情况调整

        },
        handleEnter() {
            this.$emit('enter', this.value);
        },
        clearInput() {
            this.showDropdown = false; // 关闭下拉菜单
            this.selectedOption = ''; // 清空输入
            this.$emit('input', ''); // 触发父组件的更新
        }
    }
};
</script>

<style scoped>
.select-box {
    position: relative;
    display: inline-block;
}

.dropdown {
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.dropdown.active {
    display: block;
}

.dropdown div {
    padding: 5px;
}

.dropdown div:hover {
    background-color: #f8f8f8;
}

.menu.top-full {
    top: 100%;
}
.menu.bottom-full {
    bottom: 100%;
}

</style>
