<template>
    <div style="background-color: #eeeeee;width: 100%;height: 100vh;">
        <div class="login-box">
            <p>Login</p>
            <form>
                <div class="user-box">
                    <input required="" name="" type="text" v-model="formData.username">
                    <label>Username</label>
                </div>
                <div class="user-box">
                    <input required="" name="" type="password" v-model="formData.password" @keydown.enter="submitLogin">
                    <label>Password</label>
                </div>
                <a href="#" @click="submitLogin">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Submit
                </a>
            </form>
            <p>Don't have an account? <a href="#" class="a2">Sign up!</a></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'login',
        data() {
            return {
                formData: {
                    username: '',
                    password: '',
                    code: ''
                },
                isHasCode: false
            }
        },
        mounted() {
            this.clearToken()
        },
        methods: {
            clearToken() {
                this.setCookie('AUTHORIZATION', '', -1)
            },
            submitLogin() {
                this.$api.Task.login(this.formData).then((res) => {
                    if (res.result) {
                        this.setCookie('AUTHORIZATION', 'jwt ' + res.token, 7)
                        // this.setCookie('username', res.username, 7)
                        // this.setCookie('salt', res.salt, 7)
                        // this.setCookie('hash', res.hash, 7)
                        this.$router.push({name: 'home'})
                    } else {
                        this.$myMsg.notify({
                            title: '登录失败！',
                            content: res.msg || '登录失败',
                            type: 'error',
                            time: 2000,
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
.login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    margin: 20px auto;
    transform: translate(-50%, -55%);
    background: rgba(255, 255, 255, .9);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;
}

.login-box p:first-child {
    margin: 0 0 30px;
    padding: 0;
    color: #465e65;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 1px;
}

.login-box .user-box {
    position: relative;
}

.login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #8c8c8c;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #465e65;
    outline: none;
    background: transparent;
}

.login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #465e65;
    pointer-events: none;
    transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color: #465e65;
    font-size: 12px;
}

.login-box form a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 3px;
    width: 100%;
    text-align: center;
    background: #465e65;
}

.login-box a:hover {
    background: #465e65;
    color: #465e65;
    border-radius: 5px;
}

.login-box a span {
    position: absolute;
    display: block;
}

.login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #fff);
    animation: btn-anim1 1.5s linear infinite;
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%, 100% {
        left: 100%;
    }
}

.login-box a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #fff);
    animation: btn-anim2 1.5s linear infinite;
    animation-delay: .375s;
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%, 100% {
        top: 100%;
    }
}

.login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #fff);
    animation: btn-anim3 1.5s linear infinite;
    animation-delay: .75s;
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%, 100% {
        right: 100%;
    }
}

.login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #fff);
    animation: btn-anim4 1.5s linear infinite;
    animation-delay: 1.125s;
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%, 100% {
        bottom: 100%;
    }
}

.login-box p:last-child {
    color: #aaa;
    font-size: 14px;
}

.login-box a.a2 {
    color: #465e65;
    text-decoration: none;
}

.login-box a.a2:hover {
    background: transparent;
    color: #aaa;
    border-radius: 5px;
}
</style>
